<template>
  <div>
    <h3>System Settings</h3>
    <b-card
      no-body
    >
      <p class="mb-0">
        System settings define what system BotAdmin connects to and how the connection
        is established.
      </p>
    </b-card>
    <hr>
    <settings-shared
      type-field="type"
      :settings="settings"
      :type-options="typeOptions"
      @save="save"
    >
      <template #colButtons>
        <b-col
          class="text-right"
          cols="auto"
        >
          <b-button
            v-if="showRestart"
            v-b-tooltip.hover.noninteractive.viewport
            class="mr-2"
            variant="warning"
            :title="shouldRestart?'Restart required':''"
            @click="restartControl"
          >
            <font-awesome-icon
              v-if="shouldRestart"
              icon="exclamation-circle"
            />
            Restart BotServe
          </b-button>
          <b-button
            v-b-tooltip.hover.noninteractive.viewport
            class="mr-2"
            title="Dump Tasks For Debugging"
            @click="dumpTasks"
          >
            Dump Tasks
          </b-button>
          <b-button
            @click="showRawState"
          >
            Show Raw State
          </b-button>
        </b-col>
      </template>
    </settings-shared>
    <b-modal
      id="raw-state"
      ok-only
      size="lg"
      title="Raw state"
    >
      <vue-json-pretty
        :data="JSON.parse(prettyBotserveStateText)"
      />
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import SettingsShared from 'supwiz/components/SettingsShared.vue';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
  name: 'SystemSettings',
  components: {
    SettingsShared,
    VueJsonPretty,
  },
  beforeRouteLeave(to, from, next) {
    if (this.shouldRestart) {
      this.restartControl();
    }
    next();
  },
  computed: {
    ...mapState('chatsystem', ['isUpdating']),
    ...mapGetters('chatsystem', ['chatsystemsState', 'chatsystem']),
    ...mapGetters('control', ['controlState']),
    settings() {
      if (!this.chatsystemsState) {
        return this.chatsystemsState;
      }
      const first = this.chatsystemsState[0];
      return first?.status?.settings;
    },
    typeOptions() {
      return [
        { value: 'bomgar', text: 'Bomgar (Beyond Trust)' },
        { value: 'genesyscloud', text: 'Genesys Cloud' },
        { value: 'supchat', text: 'SupChat (SupWiz)' },
        { value: 'zendesk', text: 'Zendesk Chat Conversation' },
        { value: 'zendesk-support', text: 'Zendesk Ticket Support' },
        { value: 'jsonwebhook', text: 'Json Webhook (SupWiz)' },
        { value: 'puzzel', text: 'Puzzel Webhook' },
        { value: 'puzzel-ticket', text: 'Puzzel Case Management' },
        { value: 'vonage', text: 'Vonage Voice' },
        { value: 'vonage-puzzel', text: 'Vonage Voice (w. Puzzel login)' },
        { value: 'vonage-trio', text: 'Vonage Voice (w. Trio login)' },
        { value: 'topdesk-incident', text: 'TOPdesk Incident' },
        { value: 'audiocodes', text: 'AudioCodes' },
        { value: 'user-defined', text: 'User Defined (Change this to desired Chat System)' },
      ];
    },
    shouldRestart() {
      return this.settings?.should_restart;
    },
    showRestart() {
      return this.shouldRestart;
    },
    prettyBotserveStateText() {
      return JSON.stringify(this.controlState, null, 2);
    },
  },
  methods: {
    ...mapActions('control', ['restartControl', 'dumpTasks']),
    ...mapActions('chatsystem', ['sendChatSystemSettings']),
    save(newSettings) {
      this.sendChatSystemSettings({
        settings: newSettings,
      });
    },
    showRawState() {
      this.$bvModal.show('raw-state');
    },
  },
};
</script>
