<template>
  <div>
    <h3>SupSearch Settings</h3>
    <b-card
      no-body
    >
      <p class="mb-0">
        Here you can find SupSearch-specific settings.
      </p>
    </b-card>
    <hr>
    <settings-shared
      type-field="supsearch"
      :settings="settings"
      @save="save"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import SettingsShared from 'supwiz/components/SettingsShared.vue';

export default {
  name: 'SupSearchSettings',
  components: {
    SettingsShared,
  },
  computed: {
    ...mapState('supSearch', ['isUpdating']),
    ...mapGetters('supSearch', ['supSearchState']),
    settings() {
      if (!this.supSearchState) {
        return this.supSearchState || {};
      }
      if (Object.values(this.supSearchState).length === 0) {
        return null;
      }
      return this.supSearchState;
    },
  },
  methods: {
    ...mapActions('supSearch', ['sendSupSearchSettings']),
    save(newSettings) {
      this.sendSupSearchSettings({
        settings: newSettings,
      });
    },
  },
};
</script>
