<template>
  <b-card
    class="r-75 my-3"
    title="Event log"
    body-class="p-3"
  >
    <b-row>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :per-page="logsPerPage"
          :total-rows="logCount"
          size="sm"
        />
      </b-col>
      <b-col class="text-right">
        <b-button
          v-b-tooltip.hover
          title="Clear event log"
          variant="primary"
          size="sm"
          @click.stop="clearEventLog()"
        >
          <font-awesome-icon
            icon="trash-alt"
            size="lg"
          />
        </b-button>
      </b-col>
    </b-row>

    <b-table
      striped
      hover
      small
      show-empty
      class="mb-0"
      :fields="[{timestamp: 'Timestamp'}, {details: 'Details'}, {component: 'Component'}]"
      :items="sortedLogs"
      :current-page="currentPage"
      :per-page="logsPerPage"
    >
      <template
        #cell(timestamp)="data"
      >
        {{ dateAndTimeFromTimestamp(data.item.timestamp) }}
      </template>
    </b-table>
  </b-card>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex';
import dateAndTimeFromTimestamp from '@/js/filters';

export default {
  name: 'AdminControlLog',
  data() {
    return {
      currentPage: 1,
      logsPerPage: 15,
    };
  },
  computed: {
    ...mapGetters('bots', [
      'eventLog',
    ]),
    logCount() {
      return this.eventLog.length;
    },
    sortedLogs() {
      const log = this.eventLog.slice();
      // Used to sort the elements by timestamps in increasing order.
      function sortTimestampUtilityFunction(a, b) {
        return new Date(b.timestamp) - new Date(a.timestamp);
      }
      log.sort(sortTimestampUtilityFunction);
      return log;
    },
  },
  methods: {
    ...mapMutations('bots', [
      'clearEventLog',
    ]),
    dateAndTimeFromTimestamp,
  },
};
</script>
