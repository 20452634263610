<template>
  <ol class="mb-0">
    <draggable
      :value="value"
      role="tablist"
      filter=".handler-body"
      :prevent-on-filter="false"
      @input="v=>$emit('input', v)"
    >
      <li
        v-for="(handler, i) in value"
        :key="'error_handler_'+i"
      >
        <ErrorHandler
          :ref="'error_handler_'+i"
          :handler="handler"
          @delete="deleteErrorHandler(i)"
          @update="v => updateErrorHandler(i, v)"
        />
      </li>
    </draggable>
    <li>
      <b-button
        v-b-tooltip.noninteractive.hover
        variant="primary"
        title="Add new error handler"
        @click="addErrorHandler"
      >
        <font-awesome-icon
          icon="plus"
        />
      </b-button>
    </li>
  </ol>
</template>
<script>
import Draggable from 'vuedraggable';
import ErrorHandler from '@/components/ErrorHandler.vue';

export default {
  name: 'ErrorHandlers',
  components: {
    Draggable,
    ErrorHandler,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    addErrorHandler() {
      this.$emit('input',
        [
          ...this.value,
          {
            type: 'Transfer',
            errorMessage: '',
            fallbackQueue: '',
          },
        ],
      );
      this.$nextTick(() => {
        const refName = `error_handler_${this.value.length - 1}`;
        // The element is in v-for so we need [0]
        this.$refs[refName][0].focus();
      });
    },
    deleteErrorHandler(index) {
      this.$emit('input', [...this.value.slice(0, index), ...this.value.slice(index + 1)]);
    },
    updateErrorHandler(index, update) {
      const emitted = [...this.value];
      emitted[index] = { ...emitted[index], ...update };
      this.$emit('input', emitted);
    },
  },
};
</script>
