// I'm declaring a separate file to deal with icons, such that A) we can prevent main.js from being
// bloated with icons configuration code, and you have an appropriately named file to deal with it.
import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faCheck, faPaste, faStopCircle, faUser, faDesktop,
  faLink, faCopy, faSkullCrossbones, faTrashAlt, faWrench, faList, faInfo,
  faBell, faTimes, faBars, faRoute, faPlus, faCompressAlt, faCog, faCubes,
  faEye, faEyeSlash, faExclamationCircle, faCogs, faMicrophone, faCheckCircle,
  faCircle, faTimesCircle, faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

import {
  faTimesCircle as farFaTimesCircle,
} from '@fortawesome/free-regular-svg-icons';

library.add(faBars);
library.add(faBell);
library.add(faCheck);
library.add(faCheckCircle);
library.add(faInfoCircle);
library.add(faTimesCircle);
library.add(faCopy);
library.add(faCog);
library.add(faCogs);
library.add(faCubes);
library.add(faCircle);
library.add(faMicrophone);
library.add(faCompressAlt);
library.add(faDesktop);
library.add(faExclamationCircle);
library.add(faEye);
library.add(faEyeSlash);
library.add(farFaTimesCircle);
library.add(faInfo);
library.add(faLink);
library.add(faList);
library.add(faPaste);
library.add(faPlus);
library.add(faRoute);
library.add(faSkullCrossbones);
library.add(faStopCircle);
library.add(faTimes);
library.add(faTrashAlt);
library.add(faUser);
library.add(faWrench);

export default library;
