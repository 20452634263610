import Vue from 'vue';
import store from '@/store/index';
import { getSocketURL } from '@/backend/endpoints';
/*
Methods here handles INCOMING communication received FROM backend.
If you need to communicate something TO backend, use REST API instead.
*/

import eventBus from '@/eventBus';

let socket = null;

function onConnect() {
  Vue.$log.debug('Connected');
  store.dispatch('meta/changeIsConnected', true);
}

function onDisconnect() {
  Vue.$log.debug('Socket was disconnected');
  socket = null;
  store.dispatch('meta/changeIsConnected', false);
}

function onReceivingBotsStatus(freshData) {
  const newEntries = [];
  freshData.workers.forEach((element) => {
    let status = 'No bot deployed';
    if (element.staged_bot_id) {
      status = element.accepting_chats;
    } else if (element.is_updating) {
      status = 'Updating';
    }

    const newEntry = {
      Bot: element.staged_bot_id,
      'Active conversations': element.active_chats,
      Active: element.bot_active,
      'Accepting conversations': status,
      'Worker pod': element.worker_id,
    };
    newEntries.push(newEntry);
  });
  store.dispatch('bots/updateBotStatus', newEntries);
}

function botUpdate(data) {
  const newEvent = {
    timestamp: new Date(data.timestamp * 1000),
    component: data.worker_id,
    details: `Bot updated to ${data.staged_bot_id}`,
  };
  store.dispatch('bots/addEventLog', newEvent);
}

function chatworkerError(data) {
  Vue.$log.debug('Received info about bot error: ', data);
  const newEvent = {
    timestamp: new Date(data.timestamp * 1000),
    component: data.worker_id,
    details: data.stacktrace,
    _rowVariant: 'danger',
  };
  store.dispatch('bots/addEventLog', newEvent);
}

function botServeError(data) {
  Vue.$log.debug('Received info about botServe error: ', data);
  const newEvent = {
    timestamp: new Date(data.timestamp * 1000),
    component: data.worker_id,
    details: data.stacktrace,
    _rowVariant: 'danger',
  };
  store.dispatch('bots/addEventLogWeak', newEvent);
}

function workerState(data) {
  Vue.$log.debug('Received worker state for bot: ', data);
  store.commit('bots/updateWorkerStateText', data);
}

function chatSystemState(data) {
  store.commit('chatsystem/setChatsystemsState', { newState: data.chatsystems });
  store.commit('chatsystem/setChatsystem', { chatsystem: data.chatsystems[0].type });
  // Determine sidebar allowance based on the chat systems state
  // Allow speech settings if type starts with "vonage"
  // TODO: This should be removed eventually once we are satisfied with the UX of the settings page.
  const settingsObj = data.chatsystems[0]?.status?.settings;
  if (settingsObj) {
    // Allow system settings ONLY for user-defined deployments
    const isUserDefined = settingsObj.type === 'user-defined'
                          || settingsObj?.overridden_env_vars?.type === 'user-defined';
    store.commit('sidebar/setAllowSystemSettings', isUserDefined);
    const isVoice = settingsObj.type.startsWith('vonage')
                    || settingsObj.type.startsWith('audiocodes');
    store.commit('sidebar/setAllowSpeechSettings', isVoice || isUserDefined);
  }
}

function speechState(data) {
  store.commit('speech/setLastUpdatedTimestamp', { timestamp: Date.now() });
  store.commit('speech/setSpeechState', { newState: data.state });
}

function smsState(data) {
  store.commit('sms/setLastUpdatedTimestamp', { timestamp: Date.now() });
  store.commit('sms/setSmsState', { newState: data.state });
}

function emailState(data) {
  store.commit('email/setLastUpdatedTimestamp', { timestamp: Date.now() });
  store.commit('email/setEmailState', { newState: data.state });
}

function aiEngineState(data) {
  store.commit('aiEngine/setLastUpdatedTimestamp', { timestamp: Date.now() });
  store.commit('aiEngine/setAIEngineState', { newState: data.state });
}

function supSearchState(data) {
  store.commit('supSearch/setLastUpdatedTimestamp', { timestamp: Date.now() });
  store.commit('supSearch/setSupSearchState', { newState: data.state });
}

function controlState(data) {
  store.commit('control/setControlState', { newState: data.state });
}

function routingState(data) {
  store.commit('chatsystem/setLastUpdatedTimestamp', { timestamp: Date.now() });
  store.commit('chatsystem/setQueues', { newState: data.state.queues });
  store.commit('chatsystem/setDefaultErrorHandlers', { newState: data.state.defaultErrorHandlers });
  store.commit('chatsystem/setMustMatchLanguage', { newState: data.state.options.mustMatchLanguage });
}

function deploymentState(data) {
  store.commit('bots/setImpossibleDeployment', data.state.impossible_deployment);
  store.commit('bots/setWorkersUpdating', data.state.workers_updating);
  store.commit('bots/setMaxWorkersUpdating', data.state.max_workers_updating);
  store.commit('bots/setFillFreeWith', data.state.fill_free_with);
  store.commit('bots/setDelayedDeployment', data.state.delayed_deployment);
  store.commit('bots/setStableDeployment', data.state.stable_deployment);
  store.commit('bots/setAdaptations', data.state.adaptations);
  store.commit('bots/setKnownLabels', data.state.labels);
}

function dispatchCustomMessageToHandler(event) {
  if (event && event.data && event.data === 'null') {
    throw new Error(`Got "null" message ${event}`);
  }
  const message = JSON.parse(event.data);
  if (!message) {
    throw new Error(`Could not parse event data as json: ${event.data}`);
  }
  switch (message.type) {
    case 'chatsystem_status_report':
      chatSystemState(message);
      break;
    case 'status_report':
      onReceivingBotsStatus(message);
      break;
    case 'worker_state':
      workerState(message);
      break;
    case 'control_state':
      controlState(message);
      break;
    case 'auditlog_updated':
      eventBus.$emit('auditLogUpdated');
      break;
    case 'bot_update_result':
      botUpdate(message);
      break;
    case 'error_in_chatworker':
      chatworkerError(message);
      break;
    case 'error_in_botserve':
      botServeError(message);
      break;
    case 'routing-state-report':
      routingState(message);
      break;
    case 'speech-state-report':
      speechState(message);
      break;
    case 'sms-state-report':
      smsState(message);
      break;
    case 'email-state-report':
      emailState(message);
      break;
    case 'ai-engine-state-report':
      aiEngineState(message);
      break;
    case 'supsearch-state-report':
      supSearchState(message);
      break;
    case 'deployment-state-report':
      deploymentState(message);
      break;
    default:
      throw new Error(`Unmatched message-type received: ${message.type}`);
  }
}

function initializeSocket() {
  if (socket != null) {
    if (socket.open || socket.connecting) {
      Vue.$log.debug('Socket already initialized');
      return;
    }
  }
  const token = store.getters['auth/accessToken'];
  const socketURL = getSocketURL();
  socket = new WebSocket(`${socketURL}?token=${token}`);

  socket.addEventListener('open', onConnect);
  socket.addEventListener('close', onDisconnect);
  socket.addEventListener('message', dispatchCustomMessageToHandler);
}

function closeSocket() {
  if (socket != null) {
    // If server disconnected the WebSocket then the socket is already null.
    socket = null;
  }
}

export {
  initializeSocket,
  closeSocket,
};
