<template>
  <b-card
    no-body
    class="mb-1 border errorhandler"
  >
    <b-card-header
      header-tag="header"
      class="p-0 handler-header"
      role="tab"
    >
      <b-button
        block
        href="#"
        variant="warning"
        class="text-left"
        @click="expanded = !expanded"
      >
        {{ handlerTitle }}
        <a
          v-b-tooltip.hover
          href="#"
          class="float-right"
          title="Delete this error handler"
          @click.stop.prevent="deleteHandler()"
        >
          <font-awesome-icon
            icon="trash-alt"
            color="white"
          />
        </a>
      </b-button>
    </b-card-header>
    <b-collapse
      ref="collapse_element"
      v-model="expanded"
      role="tabpanel"
    >
      <b-card-body
        class="handler-body"
      >
        <b-card-text>
          <b-input-group>
            <template #prepend>
              <b-input-group-text class="prepend bg-secondary justify-content-center">
                Fallback type
              </b-input-group-text>
            </template>
            <b-form-select
              :value="handler.type"
              :options="handlerTypes"
              @input="v => $emit('update', {type: v})"
            />
          </b-input-group>
        </b-card-text>
        <b-card-text>
          <b-input-group>
            <template #prepend>
              <b-input-group-text class="prepend bg-secondary justify-content-center">
                Error message
              </b-input-group-text>
            </template>
            <b-form-input
              :value="handler.errorMessage"
              @input="v => $emit('update', {errorMessage: v})"
            />
          </b-input-group>
        </b-card-text>
        <b-card-text v-if="handler.type === 'Transfer' || handler.type === 'Transfer On Open'">
          <b-input-group>
            <template #prepend>
              <b-input-group-text class="prepend bg-secondary justify-content-center">
                Fallback queue
              </b-input-group-text>
            </template>
            <queue-name-input
              :value="handler.fallbackQueue"
              class="flex-grow-1"
              @input="v => $emit('update', {fallbackQueue: v})"
            />
          </b-input-group>
          If the queue is not online/open, the next handler will be used.
        </b-card-text>
        <b-card-text v-if="handler.type === 'DTMF'">
          <b-input-group>
            <template #prepend>
              <b-input-group-text class="prepend bg-secondary justify-content-center">
                DTMF tones
              </b-input-group-text>
            </template>
            <b-input
              :value="handler.fallbackQueue"
              class="flex-grow-1"
              type="tel"
              :formatter="allowTelOnly"
              @input="v => $emit('update', {fallbackQueue: v})"
            />
          </b-input-group>
        </b-card-text>
        <b-card-text v-if="handler.type === 'Transfer On Open'">
          <b-input-group>
            <template #prepend>
              <b-input-group-text class="prepend bg-secondary justiry-content-center">
                Schedule group ID
              </b-input-group-text>
            </template>
            <b-input
              :value="handler.scheduleGroupId"
              class="flex-grow-1"
              @input="v => $emit('update', {scheduleGroupId: v})"
            />
          </b-input-group>
        </b-card-text>
        <b-card-text v-if="handler.type === 'Transfer On Open'">
          <b-input-group>
            <template #prepend>
              <b-input-group-text class="prepend bg-secondary justiry-content-center">
                Emergency group ID
              </b-input-group-text>
            </template>
            <b-input
              :value="handler.emergencyGroupId"
              class="flex-grow-1"
              @input="v => $emit('update', {emergencyGroupId: v})"
            />
          </b-input-group>
        </b-card-text>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>
<script>
import { mapGetters } from 'vuex';
import QueueNameInput from '@/components/QueueNameInput.vue';

export default {
  name: 'ErrorHandler',
  components: { QueueNameInput },
  props: {
    handler: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    ...mapGetters('chatsystem', [
      'isVoiceBot',
      'chatsystem',
    ]),
    allowTransferOpen() {
      return ['genesyscloud'].includes(this.chatsystem);
    },
    handlerTitle() {
      let title = 'N/A, should not happen';
      if (this.handler.type === 'Transfer' || this.handler.type === 'Transfer On Open') {
        title = `Transfer to ${this.handler.fallbackQueue} and say "${this.handler.errorMessage}"`;
      }
      if (this.handler.type === 'End conversation') {
        title = `Say "${this.handler.errorMessage}" and end conversation`;
      }
      if (this.handler.type === 'DTMF') {
        title = `Send DTMF tones "${this.handler.fallbackQueue}" and say "${this.handler.errorMessage}"`;
      }
      if (this.handler.type === 'Leave') {
        title = 'Leave and do nothing (error handled by platform)';
      }
      if (title.length > 55) {
        title = `${title.substring(0, 52)}...`;
      }
      return title;
    },
    handlerTypes() {
      return [
        { value: 'Transfer', text: 'Transfer' },
        { value: 'Transfer On Open', text: 'Transfer On Open', disabled: !this.allowTransferOpen },
        { value: 'End conversation', text: 'End conversation' },
        { value: 'DTMF', text: 'DTMF', disabled: !this.isVoiceBot },
        { value: 'Leave', text: 'Leave and do nothing' },
      ];
    },
  },
  methods: {
    deleteHandler() {
      this.$emit('delete');
    },
    focus() {
      this.$refs.collapse_element.show = true;
    },
    allowTelOnly(tel) {
      return tel.replace(/[^+*#0-9]/g, '');
    },
  },
};
</script>
<style scoped>
.errorhandler {
  max-width: 700px;
}
.handler-header {
  border-bottom: none;
}
</style>
