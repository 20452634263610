<template>
  <div>
    <h3>Voice Settings</h3>
    <b-card
      no-body
    >
      <p class="mb-0">
        Here you can find voice-specific settings.
      </p>
    </b-card>
    <hr>
    <settings-shared
      ref="settingsShared"
      type-field="provider"
      :settings="settings"
      @save="save"
    >
      <template #colButtons>
        <b-col
          class="text-right"
          cols="auto"
        >
          <b-button
            @click="reloadVoices"
          >
            Reload Voices
          </b-button>
        </b-col>
      </template>
    </settings-shared>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import SettingsShared from 'supwiz/components/SettingsShared.vue';

export default {
  name: 'VoiceSettings',
  components: {
    SettingsShared,
  },
  computed: {
    ...mapState('speech', ['isUpdating']),
    ...mapGetters('speech', ['speechState']),
    settings() {
      if (!this.speechState) {
        return this.speechState || {};
      }
      return this.speechState;
    },
  },
  methods: {
    ...mapActions('speech', ['sendSpeechSettings', 'reloadSpeechVoices']),
    save(newSettings) {
      this.sendSpeechSettings({
        settings: newSettings,
      });
    },
    reloadVoices() {
      this.$refs.settingsShared.expectNewSettings();
      this.reloadSpeechVoices();
    },
  },
};
</script>
