<template>
  <div
    v-if="isImpossibleDeployment"
    class="text-danger"
  >
    IMPOSSIBLE DEPLOYMENT
  </div>
  <div
    v-else-if="isDeployingDelayed"
    class="text-warning"
  >
    DELAYED DEPLOYMENT
  </div>
  <div
    v-else-if="isWorkersUpdating"
    class="text-warning"
  >
    WORKERS UPDATING
  </div>
  <div
    v-else-if="isDeploying"
    class="text-warning"
  >
    UPDATING DEPLOYMENT
  </div>
  <div v-else>
    {{ defaultTitle }}
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DeploymentStatus',
  props: {
    defaultTitle: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('bots', [
      'isDeploying',
      'isDeployingDelayed',
      'isWorkersUpdating',
      'isImpossibleDeployment',
    ]),
  },
};
</script>
