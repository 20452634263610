<template>
  <div>
    <b-alert
      :class="botAdminEnvironment!=='production'?'mt-1':''"
      variant="secondary"
      fade
      class="m-0 py-2 text-center deployment-alert r-0"
      :show="showDeploymentAlert && loggedIn"
    >
      <b-row align-v="center">
        <b-col>
          Deployment: {{ botAdminName }}
        </b-col>
        <font-awesome-icon
          style="float: right;"
          class="pr-3 pt-1 cursor-pointer"
          icon="times"
          @click="showDeploymentAlert=false"
        />
      </b-row>
    </b-alert>
    <app-template
      v-bind="{
        pages,
        showSidebar,
        userDetails,
        toolDetails: {
          name: 'BotAdmin',
          logo: require('./assets/supwiz-logo-flat.svg'),
          env:botAdminEnvironment
        },
        loggedIn,
      }"
      notifications-center
    >
      <template #before-nav>
        <b-badge
          v-if="hasDataOrigin && !sidebarCompact"
          :variant="dataOriginVariant"
          class="mx-3 font-weight-normal"
        >
          DATA ORIGIN: {{ chatsystemsState[0].status.settings.data_origin }}
        </b-badge>
        <b-badge
          v-if="!showDeploymentAlert && !sidebarCompact"
          class="mx-3 mt-2 font-weight-normal"
        >
          Deployment: {{ botAdminName }}
        </b-badge>
      </template>
      <template #page-content>
        <transition
          mode="out-in"
          name="fade"
        >
          <router-view />
        </transition>
      </template>
    </app-template>
  </div>
</template>

<script>
import AppTemplate from 'supwiz/components/template/AppTemplate.vue';
import {
  mapGetters, mapState,
} from 'vuex';
import { getBotAdminEnvironment, getBotAdminName } from '@/js/utils';

export default {
  name: 'App',
  components: { AppTemplate },
  data() {
    return {
      showDeploymentAlert: true,
    };
  },
  computed: {
    ...mapGetters('chatsystem', ['chatsystemsState']),
    ...mapState('sidebar', ['pageLoading', 'showSidebar']),
    ...mapGetters('sidebar', ['pages']),
    ...mapGetters('auth', ['loggedIn']),
    ...mapGetters('auth', ['displayName']),
    ...mapState('templateStore', ['sidebarCompact']),
    hasDataOrigin() {
      return Object.values(this.chatsystemsState).length
       && !!this.chatsystemsState[0].status?.settings?.data_origin;
    },
    dataOriginVariant() {
      if (this.hasDataOrigin && this.chatsystemsState[0].status.settings.data_origin === 'DEV') {
        return 'danger';
      } if (this.chatsystemsState[0].status.settings.data_origin === 'TEST') {
        return 'warning';
      }
      return 'secondary';
    },
    userDetails() {
      return { displayName: this.displayName };
    },
    botAdminEnvironment() {
      return getBotAdminEnvironment();
    },
    botAdminName() {
      return getBotAdminName();
    },
  },
};
</script>

<style>
 body, html{
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
}
#app {
  background-image:
    linear-gradient(to right, rgba(2,76,115,0.97), rgba(2,76,115,0.97)),
    url('./assets/background-pattern.svg');
}
.unsaved-text{
  font-size: 80%;
  color:#E99002 !important;
  line-height: 1.5;
  font-weight: 400;
}
.border-warning{
  border: 3px solid #E99002 !important;
  margin: -3px !important;
}
</style>
<style scoped>
.deployment-alert{
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
}
</style>
