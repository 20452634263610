<template>
  <div>
    <h3>SMS Settings</h3>
    <b-card
      no-body
    >
      <p class="mb-0">
        Here you can find SMS-specific settings.
      </p>
    </b-card>
    <hr>
    <settings-shared
      type-field="sms_provider"
      :settings="settings"
      @save="save"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import SettingsShared from 'supwiz/components/SettingsShared.vue';

export default {
  name: 'SmsSettings',
  components: {
    SettingsShared,
  },
  computed: {
    ...mapState('sms', ['isUpdating']),
    ...mapGetters('sms', ['smsState']),
    settings() {
      if (!this.smsState) {
        return this.smsState || {};
      }
      if (Object.values(this.smsState).length === 0) {
        return null;
      }
      return this.smsState;
    },
  },
  methods: {
    ...mapActions('sms', ['sendSmsSettings']),
    save(newSettings) {
      this.sendSmsSettings({
        settings: newSettings,
      });
    },
  },
};
</script>
