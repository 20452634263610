<template>
  <div class="flex-fill">
    <router-view />
    <b-modal
      :visible="promptForLogout"
      hide-header
      ok-only
      no-close-on-esc
      no-close-on-backdrop
      auto-focus-button="ok"
      @ok="doLogout"
    >
      The current session is timed out. Please sign in again.
    </b-modal>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import { initializeSocket } from '@/backend/socketBackend';

export default {
  name: 'LoggedUserBase',
  data() {
    return {
      socketReconnectionFailed: false,
      timeoutHandler: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['tokenSeemsOk']),
    ...mapGetters('meta', [
      'socketIsConnected',
    ]),
    promptForLogout() {
      return !this.tokenSeemsOk || this.socketReconnectionFailed;
    },
  },
  watch: {
    socketIsConnected(newValue) {
      if (!newValue) {
        this.attemptReconnect([0, 1, 2, 3, 4].reverse());
      }
    },
  },
  created() {
    this.initializeSocket();
  },
  unmounted() {
    clearTimeout(this.timeoutHandler);
  },
  methods: {
    initializeSocket,
    ...mapActions('auth', [
      'logout',
    ]),
    doLogout() {
      this.logout();
      this.$router.push({
        name: 'logout',
      });
    },
    attemptReconnect(waitTimes) {
      // Attempt reconnect. If it fails, try again a number of times, until eventually forcing
      // user to login again.
      try {
        this.initializeSocket();
      } catch {
        if (waitTimes.length > 0) {
          // Schedule new attempt
          const whenToExecute = waitTimes.pop() * 1000;
          console.log(`waitTimes: ${waitTimes}, whenToExecute: ${whenToExecute}`);
          const self = this;
          setTimeout(
            () => self.attemptReconnect(waitTimes), whenToExecute,
          );
        } else {
          // Inform user that session is expired (and force him/her to logout)
          this.socketReconnectionFailed = true;
        }
      }
    },
  },
};
</script>
