<template>
  <span>
    <span
      :id="'staged-bot-tooltip-target-' + _uid"
      :style="style"
    >
      {{ name }}
    </span>
    <b-tooltip
      :target="'staged-bot-tooltip-target-' + _uid"
      triggers="hover"
      custom-class="large-tooltip"
    >
      <span
        v-for="t in tooltip"
        :key="'staged_bot_tooltip-target_' + _uid + '_' + t"
      >
        {{ t }}
      </span>
    </b-tooltip>
  </span>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'StagedBotId',
  props: {
    id: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters('stagedbots', {
      botInfos: 'infos',
    }),
    style() {
      const style = {};
      if (this.disabled) {
        style.color = 'lightgrey';
      } else if (!this.hasTooltip) {
        style.color = 'red';
      }
      return style;
    },
    name() {
      const info = this.botInfos[this.id];
      if (info === undefined) {
        return this.id;
      }
      return `${info.bot_name} / ${info.name}`;
    },
    hasTooltip() {
      return this.botInfos[this.id] !== undefined;
    },
    tooltip() {
      const info = this.botInfos[this.id];
      if (info === undefined) {
        return ['Unknown Staged Bot Id'];
      }
      return [info.id, info.timestamp, info.description];
    },
  },
  watch: {
    id(newId) {
      this.fetchInfo({ id: newId });
    },
  },
  mounted() {
    this.fetchInfo({ id: this.id });
  },
  methods: {
    ...mapActions('stagedbots', [
      'fetchInfo',
    ]),
  },
};
</script>

<style scoped>
.large-tooltip::v-deep .tooltip-inner {
  max-width: 400px;
}
</style>
