import Vue from 'vue';
import botBackend from '@/backend/botManipulationBackend';

const initialState = {
  emailState: {},
  lastUpdatedTimestamp: 0,
  isUpdating: false,
};

const getters = {
  emailState: (state) => state.emailState,
  getLastUpdatedTimestamp: (state) => state.lastUpdatedTimestamp,
};

const mutations = {
  setEmailState(state, { newState }) {
    Vue.set(state, 'emailState', newState);
  },
  setLastUpdatedTimestamp(state, { timestamp }) {
    Vue.set(state, 'lastUpdatedTimestamp', timestamp);
  },
  setIsUpdating(state, value) {
    state.isUpdating = value;
  },
};

const actions = {
  async sendEmailSettings({ dispatch }, { settings }) {
    // Wait for control to confirm them
    try {
      await botBackend.sendEmailSettings(settings);
    } catch (error) {
      dispatch('templateStore/templateSendNotification', {
        title: 'Failed to update Email settings',
        text: error.response.data.error,
        variant: 'danger',
        toast: true,
      }, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
