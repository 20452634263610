<template>
  <main>
    <b-overlay
      :show="isSaving"
    >
      <b-card
        no-body
        class="r-75 d-flex flex-column px-0"
        :class="unsavedChanges?'border':''"
      >
        <div
          v-if="chosenSection"
          class="d-flex flex-row mb-3 top-buttons"
        >
          <b-button
            v-for="(item, index) in filteredConfigSections"
            :key="index"
            v-b-tooltip.hover.bottom
            :variant="isActive(item.name)?'secondary':'light'"
            :title="item.name"
            class="flex-fill p-0 "
            @click="chooseSection(item.name)"
          >
            <b-img
              :src="require(`@/assets/configIcons/${item.icon}`)"
              fluid
              class="mx-auto my-0"
              style="max-width:40px"
              alt="Responsive image"
            />
          </b-button>
        </div>
        <div
          class="mx-3 mt-0"
        >
          <b-row
            v-if="!chosenSection"
            class="mb-2 pr-2"
          >
            <b-col
              v-for="(item, index) in filteredConfigSections"
              :key="index"
              :cols="windowWidth <= 1480 ? '4':'3'"
              class="mt-2 mb-0 pl-2 pr-0"
            >
              <b-card
                style="border-radius:2px; cursor:pointer"
                no-body
                class="text-center shadow-sm cart"
                @click="chooseSection(item.name)"
              >
                <span
                  v-b-tooltip.top.hover.noninteractive.viewport
                  :title="item.tooltipText"
                >
                  <b-img
                    fluid
                    :src="require(`@/assets/configIcons/${item.icon}`)"
                    class="mx-auto my-2"
                    style="max-width:90px"
                    alt="Responsive image"
                  />

                  <h4
                    class="mb-2"
                    style="font-size: 1.4em;"
                  >
                    {{ item.name }}
                  </h4></span>
              </b-card>
            </b-col>
          </b-row>
          <div
            v-if="chosenSection"
            key="2"
            class="w-100 mb-3"
          >
            <error-handling
              v-if="chosenSection==='error-handling'"
              key="1"
              :is-loaded="isLoaded"
              @unsavedChanges="(val)=>updateUnsavedChanges(val)"
              @saveClicked="saveClicked"
            />
            <incoming-queues
              v-if="chosenSection==='incoming-queues'"
              key="2"
              :is-loaded="isLoaded"
              @unsavedChanges="(val)=>updateUnsavedChanges(val)"
              @saveClicked="saveClicked"
            />

            <system-settings
              v-if="chosenSection === 'system-settings'"
              key="3"
            />
            <voice-settings
              v-if="chosenSection === 'voice-settings'"
              key="4"
            />
            <sms-settings
              v-if="chosenSection === 'sms-settings'"
              key="5"
            />
            <email-settings
              v-if="chosenSection === 'email-settings'"
              key="6"
            />
            <AIEngineSettings
              v-if="chosenSection === 'ai-engine-settings'"
              key="7"
            />
            <SupSearchSettings
              v-if="chosenSection === 'supsearch-settings'"
              key="8"
            />
          </div>
        </div>
      </b-card>
    </b-overlay>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import ErrorHandling from '@/components/Config/ErrorHandling.vue';
import IncomingQueues from '@/components/Config/IncomingQueues.vue';
import SystemSettings from '@/components/Config/SystemSettings.vue';
import VoiceSettings from '@/components/Config/VoiceSettings.vue';
import SmsSettings from '@/components/Config/SmsSettings.vue';
import EmailSettings from '@/components/Config/EmailSettings.vue';
import AIEngineSettings from '@/components/Config/AIEngineSettings.vue';
import SupSearchSettings from '@/components/Config/SupSearchSettings.vue';

export default {
  name: 'BotconfigPage',
  components: {
    ErrorHandling,
    IncomingQueues,
    SystemSettings,
    VoiceSettings,
    SmsSettings,
    EmailSettings,
    AIEngineSettings,
    SupSearchSettings,
  },
  beforeRouteEnter(to, from, next) {
    const hash = to.hash.toLowerCase().replace('#', '');
    if (hash) {
      next((vm) => {
        vm.chooseSection(hash);
      });
    }
    next();
  },
  beforeRouteUpdate(to, from, next) {
    const hash = to.hash.toLowerCase().replace('#', '');
    if (hash) {
      this.chosenSection = hash;
    } else {
      this.chosenSection = null;
    }
    next();
  },
  data() {
    return {
      unsavedChanges: false,
      configSections: [
        { name: 'Error Handling', icon: 'errorHandling.svg', tooltipText: 'Configure default error handlers and queue error handlers' },
        {
          name: 'Incoming Queues', icon: 'incomingQueues.svg', tooltipText: 'Define queues and bot labels',
        },
      ],
      chosenSection: null,
      windowWidth: window.innerWidth,
      first_update_time: 0,
      save_time: 0,
    };
  },
  computed: {
    ...mapGetters('chatsystem', [
      'getLastUpdatedTimestamp',
    ]),
    ...mapGetters('auth', ['isSuperuser']),
    ...mapGetters('sidebar', ['speechSettingsEnabled']),
    filteredConfigSections() {
      const copy = [...this.configSections];
      if (this.isSuperuser) {
        copy.push({
          name: 'System Settings', icon: 'systemSettings.svg', tooltipText: 'Configure system settings',
        });
        copy.push({
          name: 'SMS Settings', icon: 'smsSettings.svg', tooltipText: 'Configure SMS settings',
        });
        copy.push({
          name: 'Email Settings', icon: 'emailSettings.svg', tooltipText: 'Configure Email settings',
        });
        copy.push({
          name: 'AI Engine Settings', icon: 'aiengine.svg', tooltipText: 'Configure AI Engine settings',
        });
        copy.push({
          name: 'SupSearch Settings', icon: 'systemSettings.svg', tooltipText: 'Configure SupSearch settings',
        });
        if (this.speechSettingsEnabled) {
          copy.push({
            name: 'Voice Settings', icon: 'voiceSettings.svg', tooltipText: 'Configure voice settings',
          });
        }
      }
      return copy;
    },
    isSaving() {
      return this.isLoaded && this.getLastUpdatedTimestamp <= this.save_time;
    },
    isLoaded() {
      return this.first_update_time > 0 && this.first_update_time < this.getLastUpdatedTimestamp;
    },
  },
  watch: {
    getLastUpdatedTimestamp(newValue) {
      if (this.first_update_time > 0) {
        return;
      }
      this.first_update_time = newValue;
    },
  },
  mounted() {
    this.latest_update_time = this.getLastUpdatedTimestamp;
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
    this.$root.$on('unsavedChanges', (v) => this.updateUnsavedChanges(v));
  },
  methods: {
    chooseSection(name) {
      const tabHash = `#${name.replace(/ /g, '-').toLowerCase()}`;
      if (this.$route.hash.toLowerCase() !== tabHash) {
        this.$router.replace({ hash: tabHash });
      }
      this.chosenSection = name.replace(/ /g, '-').toLowerCase();
      if (document.activeElement) {
        document.activeElement.blur();
      }
    },
    isActive(name) {
      const h = this.$route.hash;
      if (h.toLowerCase().includes(name.replace(/ /g, '-').toLowerCase())) {
        return true;
      }
      return false;
    },
    updateUnsavedChanges(val) {
      this.unsavedChanges = val;
    },
    saveClicked() {
      this.$set(this, 'save_time', this.getLastUpdatedTimestamp);
    },
  },
};

</script>
<style scoped>
.cart{
  transition: transform 50ms;
}
.cart:hover {
  transform: translateY(-5px);
}
.top-buttons{
  margin: 1px 1px 0px 1px;
}
.top-buttons > .btn{
  border-radius: 0%;
}
.top-buttons > button:first-child{
  border-top-left-radius: .75rem;
}
.top-buttons > button:last-child{
  border-top-right-radius: .75rem;
}
.border{
  border:3px solid #E99002 !important;
}
</style>
