<template>
  <div
    v-if="!isLoaded"
    class="d-block text-center"
  >
    <b-spinner />
  </div>
  <div v-else>
    <h3>Error handling</h3>
    <b-card
      no-body
    >
      <p>
        Error handlers allow you to specify what happens when an error occurs in a conversation.
      </p>
      <p>
        Error handlers are divided into queue-specific and default.
        Queue-specific handlers are picked based on which queue the conversation was picked
        up from (not the current queue). Default handlers are used if none of the queue handlers are
        successful, or if a chat comes in from a queue that does not have handlers configured.
      </p>
      <p class="mb-0">
        There are three types of handlers:
        <ul class="mb-0">
          <li>Transfer - transfer handler will work only if the target queue is online.</li>
          <li>End conversation - this handler will automatically end the conversation.</li>
          <li>DTMF - Sends DTMF tones for SIP based voice systems.</li>
        </ul>
      </p>
    </b-card>
    <hr>
    <h4>Queue error handlers</h4>
    <div
      v-for="queue in queueNames"
      :key="queue"
    >
      <b-button
        v-b-toggle="'collapse'+safeId(queue)"
        class="w-100 my-1 text-left border"
        variant="info"
      >
        {{ queue }}
      </b-button>
      <b-collapse :id="'collapse'+safeId(queue)">
        <error-handlers v-model="queues[queue].errorHandlers" />
      </b-collapse>
    </div>
    <b-button
      v-b-modal.add-queue
      variant="primary"
      class="info my-1"
    >
      Configure Additional Queue
    </b-button>
    <hr>
    <h4>Default error handlers</h4>
    <error-handlers v-model="defaultErrorHandlers" />
    <hr>
    <b-button
      variant="primary"
      :disabled="!isDirty"
      class="mt-3 d-block px-5"
      @click="saveState"
    >
      Save
    </b-button>
    <span
      v-if="isDirty"
      class="unsaved-text"
    >
      *Unsaved changes
    </span>
    <b-modal
      id="add-queue"
      title="Add Additional Queue"
      ok-title="Add"
      @ok="addQueue"
    >
      <queue-name-input v-model="extraQueue" />
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { deepCopyJson, deepEqualsJson } from 'supwiz/util/data';
import ErrorHandlers from '@/components/ErrorHandlers.vue';
import QueueNameInput from '@/components/QueueNameInput.vue';

export default {
  name: 'ErrorHandling',
  components: { QueueNameInput, ErrorHandlers },
  props: {
    isLoaded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      defaultErrorHandlers: [],
      queues: {},
      extraQueue: '',
    };
  },
  computed: {
    ...mapGetters('chatsystem', [
      'getQueues',
      'getDefaultErrorHandlers',
      'getMustMatchLanguage',
    ]),
    isDefaultHandlersDirty() {
      return !deepEqualsJson(this.defaultErrorHandlers, this.getDefaultErrorHandlers);
    },
    isQueuesDirty() {
      return !deepEqualsJson(this.queues, this.getQueues);
    },
    isDirty() {
      return this.isDefaultHandlersDirty || this.isQueuesDirty;
    },
    queueNames() {
      return Object.keys(this.queues);
    },
  },
  watch: {
    isDirty(newVal) {
      this.$emit('unsavedChanges', newVal);
    },
    getDefaultErrorHandlers(newValue) {
      if (deepEqualsJson(newValue, this.defaultErrorHandlers)) {
        return;
      }
      if (this.isLoaded) {
        this.$bvToast.toast('Default error handlers have been changed by someone else; '
            + 'you may experience edit conflicts and loss of data', {
          title: 'Default Error Handlers changed',
          autoHideDelay: 1000,
          variant: 'warning',
        });
      }
      Vue.set(this, 'defaultErrorHandlers', deepCopyJson(newValue));
    },
    getQueues(newValue) {
      if (deepEqualsJson(newValue, this.queues)) {
        return;
      }
      if (this.isLoaded) {
        this.$bvToast.toast('Queues have been changed by someone else; '
            + 'you may experience edit conflicts and loss of data', {
          title: 'Queues routing changed',
          autoHideDelay: 1000,
          variant: 'warning',
        });
      }
      Vue.set(this, 'queues', deepCopyJson(newValue));
    },
  },
  beforeDestroy() {
    this.$emit('unsavedChanges', false);
  },
  beforeMount() {
    Vue.set(this, 'defaultErrorHandlers', deepCopyJson(this.getDefaultErrorHandlers));
    this.queues = deepCopyJson(this.getQueues);
  },
  methods: {
    ...mapActions('chatsystem', [
      'sendRoutingConfig',
    ]),
    saveState() {
      this.$emit('saveClicked', null);
      this.sendRoutingConfig({
        queues: this.queues,
        defaultErrorHandlers: this.defaultErrorHandlers,
        options: { getMustMatchLanguage: this.getMustMatchLanguage },
      });
    },
    addQueue() {
      if (this.extraQueue) {
        this.queues[this.extraQueue] = {};
        this.saveState();
      }
    },
    safeId(id) {
      // Replace spaces with a char that is not likely to be used (in a department name)
      if (!id) return id;
      return id.replace(/[ \r\n\t]/g, '\\');
    },
  },
};
</script>
