<template>
  <div>
    <chat-system-details />
    <admin-worker-status />
    <admin-deploy-bot />
    <admin-commands />
  </div>
</template>
<script>
import ChatSystemDetails from '@/components/ChatSystemDetails.vue';
import AdminWorkerStatus from '@/components/AdminWorkerStatus.vue';
import AdminDeployBot from '@/components/AdminDeployBot.vue';
import AdminCommands from '@/components/AdminCommands.vue';

export default {
  name: 'AdvancedPage',
  components: {
    ChatSystemDetails, AdminWorkerStatus, AdminDeployBot, AdminCommands,
  },
  data() {
    return {
    };
  },
};
</script>
