import { render, staticRenderFns } from "./CompletionInput.vue?vue&type=template&id=4231c9df"
import script from "./CompletionInput.vue?vue&type=script&lang=js"
export * from "./CompletionInput.vue?vue&type=script&lang=js"
import style0 from "./CompletionInput.vue?vue&type=style&index=0&id=4231c9df&prod&lang=css"


/* normalize component */
import normalizer from "!../../../products/botadmin/frontend/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports