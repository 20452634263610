<template>
  <div>
    <b-input-group class="h-100">
      <b-input-group-prepend
        v-b-popover.viewport.hover.top="description"
        :style="minKeyWidth?`min-width: ${minKeyWidth}px;`:''"
      >
        <b-input-group-text class="bg-primary text-white w-100 justify-content-center key-prop">
          {{ keyProp }}
        </b-input-group-text>
      </b-input-group-prepend>
      <b-form-group
        class="mb-0 h-100"
        :style="inputWidth?`width:calc(100% - ${inputWidth}px) !important`:'flex-grow:100;'"
      >
        <b-form-textarea
          v-model="inputValue"
          rows="2"
          max-rows="2"
          :disabled="disabled"
          class="input-box"
          :style="`font-family: ${showPassword?'inherit':'password'}`"
        />
      </b-form-group>

      <b-input-group-append>
        <b-button
          class="password-btn"
          :disabled="!inputValue || inputValue.length===0"
          @click="togglePassword"
        >
          <font-awesome-icon
            :icon="showPassword?'eye-slash':'eye'"
          />
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <small
      v-if="currentPassword"
      :style="`margin-left:${minKeyWidth}px;`"
      class="text-muted"
    >
      Current password: {{ currentPassword }}
    </small>
  </div>
</template>
<script>
export default {
  name: 'PasswordInput',
  props: {
    keyProp: {
      type: String,
      required: true,
    },
    valueProp: {
      type: String,
      default() {
        return '';
      },
    },
    minKeyWidth: {
      type: Number,
      default: 0,
    },
    currentPassword: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      description: '',
      showPassword: false,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.valueProp;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    inputWidth() {
      return this.minKeyWidth + 35;
    },
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
      document.activeElement.blur();
    },
  },
};
</script>
<style scoped>
.input-box{
    border: 1px solid #ccc !important;
    border-radius: 0px;
    min-height: 37px;
    color:grey;
    padding: 0.375rem 0.5rem;
    font-size: 0.9375rem;
    font-weight: 400;
    font-family: 'password';
}
@font-face {
    font-family: 'password';
    src: url('~@/assets/password.ttf') format('truetype');
}
.input-box:focus-visible{
    outline: 0px solid #ccc;
    border-color: #ccc;
    border:none !important;
    /* box-shadow: 0 0 0 -3px rgb(91, 192, 222); */
    border: 1px solid #ccc !important;
    border-radius: 0px !important;
}
</style>
