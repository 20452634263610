import Vue from 'vue';
import axios from 'axios';
import { endpoints } from '@/backend/endpoints';
import { authHeader } from '@/helpers/authHeader';

const initialState = {
  controlState: null,
};

const getters = {
  controlState: (state) => state.controlState,
};

const mutations = {
  setControlState(state, { newState }) {
    Vue.set(state, 'controlState', newState);
  },
};

async function sendControlCommand(dispatch, data, actionMsg, errorMsg) {
  try {
    const config = { headers: authHeader() };
    await axios.post(endpoints.controlManipulation, data, config);
    dispatch('templateStore/templateSendNotification', {
      title: 'Request accepted',
      text: actionMsg,
      variant: 'info',
      toast: true,
    }, { root: true });
  } catch (error) {
    dispatch('templateStore/templateSendNotification', {
      title: errorMsg,
      text: `${errorMsg}: ${error.message}`,
      variant: 'warning',
      toast: true,
    }, { root: true });
  }
}

const actions = {
  async restartControl({ dispatch }) {
    const data = { command: 'restart' };
    await sendControlCommand(dispatch, data, 'Restarting...', 'Could not restart BotServe');
  },
  async dumpTasks({ dispatch }) {
    const data = { command: 'dump-tasks' };
    await sendControlCommand(dispatch, data, 'Dumping Tasks...', 'Could not dump tasks');
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
