<template>
  <div>
    <b-overlay :show="isUpdating">
      <b-card
        class="r-75 mb-3"
        body-class="p-3"
      >
        <b-row>
          <b-col class="my-auto px-3 text-center border-right" cols="auto">
            <strong>System status</strong>
            <br>
            <font-awesome-icon
              id="status-details"
              class="mt-1 mb-0 h5"
              icon="circle"
              :color="getColor(chatSystemState)"
            />
            <b-popover
              target="status-details"
              triggers="hover"
              placement="right"
            >
              <b-row no-gutters>
                <b-col class="my-auto">
                  Support system:
                </b-col>
                <b-col cols="auto" class="pl-2 my-auto">
                  <font-awesome-icon
                    class="my-auto"
                    icon="circle"
                    :color="getColor(chatSystemState)"
                  />
                </b-col>
              </b-row>
              <b-row no-gutters>
                <b-col class="my-auto">
                  BotServe:
                </b-col>
                <b-col cols="auto" class="pl-2 my-auto">
                  <font-awesome-icon
                    icon="circle"
                    :color="getColor(botserveAlive && socketIsConnected)"
                  />
                </b-col>
              </b-row>
              <b-row no-gutters>
                <b-col class="my-auto">
                  BotAdmin
                </b-col>
                <b-col
                  cols="auto"
                  class="pl-2 my-auto"
                >
                  <font-awesome-icon
                    icon="circle"
                    :color="getColor(socketIsConnected)"
                  />
                </b-col>
              </b-row>
            </b-popover>
          </b-col>
          <b-col class="px-3 text-center my-auto border-right" cols="auto">
            <strong>System type</strong>
            <br>
            <span>
              {{ systemType.toUpperCase() }}
            </span>
          </b-col>
          <b-col class="px-3 text-center my-auto border-right" cols="auto">
            <strong>Bot Status</strong>
            <span
              v-b-tooltip.hover
              v-b-toggle.availability-description-sidebar
              class="cursor-pointer"
              title="Click to get an explanation"
            /><br>
            <span v-if="botserveAlive && socketIsConnected">
              {{ botStatus }}
            </span>
            <mark
              v-else
              v-b-tooltip.hover
              title="Last known value"
            >
              {{ botStatus }}
            </mark>
          </b-col>
          <b-col />
          <b-col
            cols="auto"
            class="my-auto"
          >
            <b-dropdown no-caret toggle-class="px-3" right menu-class="bg-white">
              <template #button-content>
                Actions
              </template>
              <b-dropdown-item @click="resetChatSystemConnection">
                Reset connection
              </b-dropdown-item>
              <b-dropdown-item
                v-if="chatsystemSupportsOnlineInvisibleActions"
                v-b-tooltip.hover.left="disableMakeOnline?'Already online':''"
                :disabled="disableMakeOnline"
                @click="activateChatSystem"
              >
                Make bot online
              </b-dropdown-item>
              <b-dropdown-item
                v-if="chatsystemSupportsOnlineInvisibleActions"
                v-b-tooltip.hover.left="disableMakeInvisible?'Already offline':''"
                :disabled="disableMakeInvisible"
                @click="deactivateChatSystem"
              >
                Make bot invisible
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
    <b-card
      class="r-75 mb-3"
      body-class="p-3"
    >
      <b-card-title>
        <deployment-status default-title="Current deployment" />
      </b-card-title>
      <b-table
        :items="deploymentItems"
        borderless
        show-empty
        hover
        empty-text="No workers"
        :fields="deploymentFields"
      >
        <template #cell(name)="item">
          <staged-bot-id
            v-if="item.value"
            :id="item.value"
          />
        </template>
      </b-table>
      <b-overlay :show="deployLoading">
        <b-input-group class="mt-3">
          <b-form-input
            v-model="botToStage"
            placeholder="Id of staged bot version (from BotStudio)"
          />
          <b-input-group-append>
            <b-button
              v-b-tooltip.hover
              title="Paste Staging Id"
              @click="pasteStagingId"
            >
              <font-awesome-icon
                icon="paste"
                size="lg"
              />
            </b-button>
            <b-button
              :disabled="$v.botToStage.$invalid"
              variant="primary"
              class="default-btn"
              @click="deployClicked"
            >
              Deploy
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-overlay>
    </b-card>
    <admin-botserve-log />
    <b-modal
      id="no-variant-confirm"
      title="Deploying new staged bot"
      ok-title="Deploy"
      @ok="deployBot"
    >
      <b-alert variant="warning" :show="deployingNewBot">
        You are changing bot. The new staged bot comes from a different bot than the one currently
        deployed.
      </b-alert>
      <b-alert variant="warning" :show="deployingDifferentVariants">
        The language of the new bot does not match the existing bot's language
      </b-alert>
      Are you sure you want to deploy this bot?
    </b-modal>
    <b-modal
      id="variant-settings"
      title="Variant Settings"
      @ok="deployAdaptation"
    >
      <b-alert variant="warning" :show="deployingNewBot">
        You are changing bot. The new staged bot comes from a different bot than the one currently
        deployed.
      </b-alert>
      <b-alert variant="warning" :show="deployingDifferentVariants">
        New variants do not match currently deployed variants
      </b-alert>
      <b-row
        class="mb-2"
      >
        <b-col>
          <strong>Variant names</strong>
        </b-col>
        <b-col
          cols="auto"
          class="text-right"
        >
          <strong>Adjust replicas</strong>
        </b-col>
      </b-row>
      <AdaptationRow
        v-for="row in adaptationsList"
        :key="row.id"
        :value="row"
        @input="v => updateValue(row.id, v)"
      />
    </b-modal>

    <sidebar-description
      id="availability-description-sidebar"
      title="Bot status"
    >
      <template #content>
        <p>
          Below you can find a description of different bot status values:
        </p>
        <ul>
          <li>
            <strong>UNKNOWN:</strong>
            There is no connection with BotAdmin backend, so bot status is unknown.
          </li>
          <li>
            <strong>OFFLINE/NO CONNECTION:</strong>
            There is no connection from BotAdmin to the support system.
          </li>
          <li>
            <strong>Invisible:</strong>
            There is a connection from BotAdmin to the support system,
            however the bot is set to a NONE-online status in the support system,
            and it will not pick up new conversations, even if offered.
          </li>
          <li>
            <strong>ONLINE:</strong>
            There is a connection from BotAdmin to the support system.
            The bot is set to ONLINE status, and will pick up conversations.
          </li>
        </ul>
        <p>
          Additionally if bot status is highlighted <mark>like this</mark>, it means that shown
          value is the last known value. Usually this indicates that the bot status is being
          updated.
        </p>
        <p>
          If there is no connection it means BotAdmin is not logged in,
          and does not use an agent license.
        </p>
        <p>
          The name of the status is the same for all support systems,
          even though they call each state something different; on-queue, away etc.
        </p>
        <p>
          If the availability is ONLINE, but there are no workers listed,
          BotAdmin will pick up conversations, and then transfer the conversation to fallback queue.
          This can happen because workers are not up and running or
          are restarting/crashing. So it is advisable to always have 2 workers,
          as the probability both restarting at the same time is low.
        </p>
      </template>
    </sidebar-description>
  </div>
</template>
<script>
import { isEqual } from 'lodash';
import {
  mapActions, mapGetters, mapState,
} from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import SidebarDescription from 'supwiz/components/SidebarDescription.vue';
import AdminBotserveLog from '@/components/AdminBotserveLog.vue';
import StagedBotId from '@/components/StagedBotId.vue';
import { UUID_REGEX } from '@/js/utils';
import AdaptationRow from '@/components/AdaptationRow.vue';
import DeploymentStatus from '@/components/DeploymentStatus.vue';

export default {
  name: 'ManagePage',
  components: {
    AdminBotserveLog,
    StagedBotId,
    AdaptationRow,
    SidebarDescription,
    DeploymentStatus,
  },
  mixins: [validationMixin],
  data() {
    return {
      botserveAlive: false,
      timeoutHandler: null,
      deploymentFields: [
        {
          key: 'name',
        },
        {
          key: 'activeConversations',
        },
        {
          key: 'replicas',
        },
      ],
      botToStage: '',
      adaptationsList: [],
      isDeploying: false,
      deployingNewBot: false,
      deployingDifferentVariants: false,
      deployLoading: false,
    };
  },

  computed: {
    ...mapState('chatsystem', ['isUpdating']),
    ...mapState('control', ['controlState']),
    ...mapGetters('chatsystem', [
      'chatsystemsState',
      'chatsystem',
    ]),
    ...mapGetters('meta', [
      'socketIsConnected',
    ]),
    ...mapGetters('bots', [
      'adaptations',
      'botsCurrentState',
      'botsCurrentStateTimestamp',
    ]),
    ...mapGetters('stagedbots', [
      'infos',
    ]),
    systemType() {
      const first = this.chatsystemsState[0];
      return (first?.type || 'unknown');
    },
    botStatus() {
      if (!this.socketIsConnected) {
        return 'UNKNOWN';
      }
      return Object.values(this.chatsystemsState).length ? this.chatsystemsState[0].availability : 'NO CONNECTION';
    },
    chatSystemState() {
      return Object.values(this.chatsystemsState).length ? this.chatsystemsState[0].availability : '';
    },
    disableMakeInvisible() {
      return !(this.chatsystemsState[0].availability === 'ONLINE');
    },
    chatsystemSupportsOnlineInvisibleActions() {
      // Put logic here knowledgeable of whether the chatsystem supports the make online and make
      // invisible actions. Currently we support two chatsystems, of which supchat does not
      // support the make online and make invisible action.
      const supportedChatsystems = ['supchat', 'zendesk', 'zendesk-support', 'genesyscloud', 'bomgar', 'bomgar-agent',
        'vonage', 'vonage-puzzel', 'vonage-trio', 'topdesk-incident', 'audiocodes'];
      return supportedChatsystems.includes(this.chatsystem);
    },
    disableMakeOnline() {
      return this.chatsystemsState[0].availability === 'ONLINE';
    },
    deploymentItems() {
      const items = {};
      this.botsCurrentState.forEach((item) => {
        if (items[item.Bot]) {
          items[item.Bot].replicas += 1;
          items[item.Bot].activeConversations += item['Active conversations'];
        } else {
          items[item.Bot] = { name: item.Bot, activeConversations: item['Active conversations'], replicas: 1 };
        }
      });
      return Object.values(items);
    },
    stagedBotIds() {
      // Staged bot IDs of currently deployed/delayed/failing bots (including 0 replicas)
      // deployed or not
      const set = new Set();
      // Adaptations are set
      Object.keys(this.adaptations || {}).forEach(set.add, set);
      const deployment = this.controlState?.deployment;
      // Delayed or failing adaptations
      Object.keys(deployment?.adaptations || {}).forEach(set.add, set);
      // Bots deployed successfully
      this.botsCurrentState.map((x) => x.Bot).filter(Boolean).forEach(set.add, set);
      // Delayed or failing default bot
      if (deployment?.default) {
        set.add(deployment.default);
      }
      return set;
    },
    deployedBotIds() {
      const set = new Set();
      for (const id of this.stagedBotIds) {
        if (id in this.infos) {
          set.add(this.infos[id].botId);
        }
      }
      return set;
    },
    deployedStagedBotIds() {
      const set = new Set();
      this.stagedBotIds.forEach((x) => {
        if ((this.adaptations || {})[x] === 0
          || (this.controlState?.deployment?.adaptations || {})[x] === 0
        ) {
          return;
        }
        set.add(x);
      });
      return set;
    },
    stagedBotLanguages() {
      return this.botSetLanguage(this.stagedBotIds);
    },
    deployedStagedBotLanguages() {
      return this.botSetLanguage(this.deployedStagedBotIds);
    },
    hasDeployment() {
      return this.stagedBotIds.size > 0;
    },
  },
  watch: {
    botsCurrentStateTimestamp() {
      this.botserveAlive = true;
      // Turn it off after some time
      const self = this;
      clearTimeout(this.timeoutHandler);
      this.timeoutHandler = setTimeout(() => {
        self.botserveAlive = false;
      },
      3 * 1000);
    },
  },
  unmounted() {
    clearTimeout(this.timeoutHandler);
  },
  methods: {
    ...mapActions('chatsystem', [
      'deactivateChatSystem',
      'activateChatSystem',
      'resetChatSystemConnection',
    ]),
    ...mapActions('sidebar', ['showWarning']),
    ...mapActions('bots', {
      doDeployBot: 'deployBot',
    }),
    ...mapActions('stagedbots', [
      'fetchInfo',
      'fetchSetInfo',
    ]),
    updateValue(id, value) {
      this.adaptationsList.find((e) => e.id === id).count = parseInt(value, 10);
    },
    getColor(value) {
      if ([true, 'ONLINE'].includes(value)) return 'green';
      if (value === 'INVISIBLE') return 'orange';
      return 'red';
    },
    botSetLanguage(botSet) {
      const set = new Set();
      botSet.forEach((id) => {
        if (id in this.infos) {
          set.add(this.infos[id]?.language);
        }
      });
      return set;
    },
    async fetchMissingBotInfo() {
      const promises = [];
      for (const id of this.stagedBotIds) {
        if (id in this.infos) {
          continue;
        }
        promises.push(this.fetchInfo({ id }));
      }
      try {
        await Promise.all(promises);
      } catch (e) {
        this.$bvToast.toast(
          'Failed to lookup bot details of existing deployment. Deployment warnings about mismatched bots might appear.',
          {
            title: 'Bot Details',
            autoHideDelay: 1000,
            variant: 'danger',
          },
        );
      }
    },
    async deployClicked() {
      try {
        this.deployLoading = true;
        await this.handleDeployClicked();
      } finally {
        this.deployLoading = false;
      }
    },
    async handleDeployClicked() {
      this.deployingNewBot = false;
      this.deployingDifferentVariants = false;
      await this.fetchMissingBotInfo();
      const botToStageId = this.botToStage.trim();
      const resp = await this.fetchSetInfo({ id: botToStageId });
      if (resp.data.type === 'unknown-id') {
        this.showWarning({
          title: 'Staged bot ID not found',
          text: `Staged bot ID ${botToStageId} not found in the connected BotStudio.`,
          variant: 'danger',
        });
        return;
      }
      const requestedStageBotInfos = resp.data.staged_bots;
      const requestedStageBotIds = requestedStageBotInfos.map((e) => e.id);
      // all staged variants have the same platforms, so we check only first one
      const platforms = requestedStageBotInfos[0]?.platforms;
      if (this.systemType === 'unknown') {
        if (!(await this.$bvModal.msgBoxConfirm(
          `BotAdmin system type is currently ${this.systemType}. Please verify that you want to deploy a bot with platforms: ${platforms.join(', ')}.`,
          {
            title: 'Unknown platform',
            okTitle: 'Deploy anyway',
            okVariant: 'warning',
          },
        ))) {
          return;
        }
      } else if (this.systemType !== 'user-defined' && !platforms.includes(this.systemType)) {
        if (!(await this.$bvModal.msgBoxConfirm(`The bot you try to deploy does not support ${this.systemType} system type.`, {
          title: 'Unsupported platform',
          okTitle: 'Deploy anyway',
          okVariant: 'warning',
        }))) {
          return;
        }
      }
      const fetchVariantInfo = [];
      for (const id of requestedStageBotIds) {
        fetchVariantInfo.push(this.fetchInfo({ id }));
      }
      try {
        await Promise.all(fetchVariantInfo);
      } catch (e) {
        this.$bvToast.toast(
          'Failed to lookup bot details. Only bot uuid will be available.',
          {
            title: 'Bot Details',
            autoHideDelay: 1000,
            variant: 'danger',
          });
        // user does not get nice names, but he can continue as names are UI only
      }
      const newBotIds = new Set(requestedStageBotInfos.map((e) => e.botId));
      this.deployingNewBot = this.hasDeployment && !isEqual(this.deployedBotIds, newBotIds);

      const newVariants = new Set(requestedStageBotInfos.map((e) => e.language));
      if (this.hasDeployment) {
        this.deployingDifferentVariants = !isEqual(newVariants.size === 1
          // We are deploying a single bot. Compare actual deployed languages
          ? this.deployedStagedBotLanguages
          // We are deploying a variant. Compare against staged variant set languages
          : this.stagedBotLanguages, newVariants);
      }

      if (requestedStageBotInfos.length === 1) {
        this.$bvModal.show('no-variant-confirm');
      } else {
        const variantCount = {};
        // Code for keeping the same worker count for same variants.
        // This is somewhat kludge, as we also want to have 0 count for
        // 1) newly added variants or
        // 2) old variant set to 0 or
        // 3) old variant pruned such it is not mentioned in old adaptations.
        // So we set all new variants to 0 if there is an existing adaptation, no matter content.
        // Special case: Deploying a new bot with a deleted variant:
        // - Keep
        const defaultCount = this.adaptations ? 0 : 1;
        for (const requestedStagedBotId of requestedStageBotIds) {
          const info = this.infos[requestedStagedBotId];
          // Take care of cases where the variant is not loaded/does not exist
          const variantName = info === undefined ? requestedStagedBotId : info.variant_name;
          variantCount[variantName] = {
            id: requestedStagedBotId,
            variant: variantName,
            count: defaultCount,
            state: 'new',
          };
        }

        for (const adaptation of Object.entries(this.adaptations || {})) {
          const adaptId = adaptation[0];
          const adaptCount = adaptation[1];
          const info = this.infos[adaptId];
          // Take care of cases where the variant is not loaded/does not exist
          const variantName = info === undefined ? adaptId : info.variant_name;

          if (variantCount[variantName]) {
            variantCount[variantName].count = Math.max(0, adaptCount);
            variantCount[variantName].state = 'exists';
          } else if (adaptCount > 0) {
            variantCount[variantName] = {
              id: adaptId,
              variant: variantName,
              count: adaptCount,
              state: 'outdated',
            };
          }
        }

        this.adaptationsList = [];
        for (const adaptation of Object.values(variantCount)) {
          this.adaptationsList.push({
            id: adaptation.id,
            count: adaptation.count,
            state: adaptation.state,
          });
        }

        this.$bvModal.show('variant-settings');
      }
    },

    async deployAdaptation() {
      const adaptations = {};
      for (const { id, count, state } of this.adaptationsList) {
        if (count === 0 && state === 'outdated') {
          // Prune outdated adaptations not in use anymore
          continue;
        }
        adaptations[id] = count;
      }
      await this.doDeployBot({
        adaptations,
        fillFreeWith: 'Unchanged',
      });
    },
    async deployBot() {
      if (this.botToStage) {
        this.botToStage = this.botToStage.trim();
        await this.doDeployBot({
          defaultStagedBotId: this.botToStage,
          adaptations: {},
          fillFreeWith: 'Default',
        });
      }
    },
    async pasteStagingId() {
      const clipboard = navigator.clipboard;
      if (clipboard) {
        if (clipboard.readText) {
          // awaited, as browser may ask user permission
          this.botToStage = await clipboard.readText();
        } else {
          this.$bvToast.toast('Clipboard Read Permission Denied', {
            title: 'Clipboard',
            autoHideDelay: 1000,
            variant: 'danger',
          });
        }
      }
    },
  },
  validations: {
    botToStage: {
      required,
      isUUID(value) {
        if (!value) {
          return false;
        }
        return !!value.trim().match(UUID_REGEX);
      },
    },
  },
};
</script>
<style scoped>
.default-btn{
    width:150px;
}
</style>
