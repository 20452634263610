import Vue from 'vue';

/**
 * Use the eventBus as a mechanism to signal between un-coupled components, think 'global events'.
 * This is highly experimental; another alternative considered is to have and
 * watch-and-react-accordingly-upon a property being changed in Vuex store. The downside with that
 * alternative is that one must introduce properties for each distinct "event" you wish to react
 * upon.
 */
const eventBus = new Vue();
export default eventBus;
