<template>
  <div>
    <b-form-input
      :value="value"
      :state="$v.value.$invalid ? false : null"
      placeholder="Enter queue name"
      autofocus
      aria-describedby="NoQuotes"
      @input="v => $emit('input', v)"
    />
    <b-form-invalid-feedback
      id="NoQuotes"
    >
      <div v-if="!$v.value.noQuotes.$invalid">
        The queue name should not be quoted.
      </div>
    </b-form-invalid-feedback>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';

export default {
  name: 'QueueNameInput',
  mixins: [validationMixin],
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  validations: {
    value: {
      noQuotes(name) {
        if (!name) return true;
        const first = name[0];
        const last = name[name.length - 1];
        if (first === "'" && last === "'") return false;
        if (first === '"' && last === '"') return false;
        return true;
      },
    },
  },
};
</script>
