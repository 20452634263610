import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { authHeader } from '@/helpers/authHeader';
import { getBaseRESTAPIURL } from '@/backend/endpoints';

const BASE_URL = getBaseRESTAPIURL();

Vue.use(VueAxios, axios);

const endpoints = {
  login: `${BASE_URL}/auth/obtain-token/`,
  verifyToken: `${BASE_URL}/verify-token`,
  refreshToken: `${BASE_URL}/auth/refresh-token/`,
  bot: `${BASE_URL}/bot`,
  deployBot: `${BASE_URL}/bot/deploy`,
};

async function login(username, password) {
  const payload = {
    username,
    password,
  };
  try {
    const response = await Vue.axios({
      url: endpoints.login,
      data: payload,
      method: 'post',
      validateStatus: () => true, // We'll handle error-codes ourselves.
    });
    const statusCode = response.status;
    if (statusCode === 200) {
      // Store JSON Web Token
      const data = response.data;
      return {
        status: 'success',
        tokens: {
          access: data.access,
          refresh: data.refresh,
        },
      };
    } if (response.status === 401) {
      return { status: 'denied' };
    }
    return {
      status: `unhandled ${response.status}`,
    };
  } catch (error) {
    Vue.$log.debug(error);
    return {
      status: 'unhandled',
    };
  }
}

async function refreshAccessToken(encodedRefreshToken) {
  return axios.post(endpoints.refreshToken, {
    refresh: encodedRefreshToken,
  });
}

async function verifyToken(token) {
  const payload = {
    token,
  };
  const auth = authHeader();
  const response = await Vue.axios({
    url: endpoints.verifyToken,
    method: 'post',
    headers: auth,
    data: payload,
  });
  if (response.statusCode === 200) {
    return 'success';
  }
  return 'rejected';
}
const actions = {
  login,
  verifyToken,
  refreshAccessToken,
};

export default actions;
