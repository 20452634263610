import { render, staticRenderFns } from "./UserDetails.vue?vue&type=template&id=47b45c59&scoped=true"
import script from "./UserDetails.vue?vue&type=script&lang=js"
export * from "./UserDetails.vue?vue&type=script&lang=js"
import style0 from "./UserDetails.vue?vue&type=style&index=0&id=47b45c59&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../products/botadmin/frontend/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47b45c59",
  null
  
)

export default component.exports