<template>
  <b-card
    class="r-75 mb-3"
    body-class="p-3"
    title="Support system details"
  >
    <b-table
      :items="valuesForChatsystemTable"
      :fields="chatsystemTableFields"
      borderless
      hover
      class="mb-0"
    >
      <template
        #cell(type)="data"
      >
        {{ data.item.status.handler.type.toUpperCase() }}
      </template>

      <template
        #cell(availability)="data"
      >
        <span>
          <font-awesome-icon
            v-if="data.value === 'ONLINE'"
            icon="check"
            style="color:green"
          />
        </span>
        <b
          v-if="data.value === 'ONLINE'"
          class="text-info"
        >
          {{ data.value.toUpperCase() }}
        </b>

        <span>
          <font-awesome-icon
            v-if="data.value === 'INVISIBLE'"
            icon="stop-circle"
            style="color:red"
          />
        </span>
        <b
          v-if="data.value === 'INVISIBLE'"
          class="text-warning"
        >
          {{ data.value.toUpperCase() }}
        </b>

        <span>
          <font-awesome-icon
            v-if="data.value === 'OFFLINE'"
            icon="stop-circle"
            style="color:red"
          />
        </span>
        <b
          v-if="data.value === 'OFFLINE'"
          class="text-warning"
        >
          {{ 'NO CONNECTION' }}
        </b>
      </template>

      <template #cell(incomingQueue)>
        {{ incomingQueues.join(', ') }}
      </template>

      <template #cell(chatsystemActions)="row">
        <b-button
          size="sm"
          @click="showChatSystemState(row)"
        >
          <font-awesome-icon icon="info" />
        </b-button>
      </template>
    </b-table>
    <b-modal
      id="chatsystem-status-modal"
      ok-only
      size="lg"
      title="Support system details"
    >
      <vue-json-pretty
        :data="JSON.parse(chatSystemStatusDetails)"
      />
    </b-modal>
  </b-card>
</template>

<script>
import {
  mapGetters, mapState,
} from 'vuex';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
  name: 'ChatSystemDetails',
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      // Set this text before presenting modal
      chatSystemStatusDetails: null,
    };
  },
  computed: {
    ...mapState('chatsystem', ['isUpdating']),
    ...mapGetters('chatsystem', [
      'chatsystemsState',
      'chatsystem',
    ]),
    chatsystemTableFields() {
      if (!this.chatsystemsState) {
        return [];
      }
      return [
        {
          key: 'type',
        },
        {
          key: 'availability',
        },
        {
          key: 'incomingQueue',
          label: 'Incoming queue',
        },
        {
          key: 'chatsystemActions',
          label: 'Details',
        },
      ];
    },
    valuesForChatsystemTable() {
      return Object.values(this.chatsystemsState);
    },
    fallbackQueue() {
      if (!this.chatsystemsState) {
        return '';
      }
      return this.chatsystemsState[0].status.settings.fallback_queue_name;
    },
    incomingQueues() {
      if (!this.chatsystemsState) {
        return [];
      }
      const firstSystem = this.chatsystemsState[0];
      if (firstSystem.status.settings.incoming_queue) {
        // This is just 1 queue, so we need to have it as a list.
        return [firstSystem.status.settings.incoming_queue];
      }
      return firstSystem.status.handler.incoming_queues;
    },
  },
  methods: {
    showChatSystemState(row) {
      this.chatSystemStatusDetails = JSON.stringify(row.item.status, null, 2);
      this.$bvModal.show('chatsystem-status-modal');
    },
  },
};
</script>

<style scoped>
</style>
