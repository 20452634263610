import Vue from 'vue';
import stagedBotBackend from '@/backend/stagedBotBackend';

const initialState = {
  stagedBotInfo: {},
};

const getters = {
  infos: (state) => state.stagedBotInfo,
};

const mutations = {
  setInfo(state, payload) {
    Vue.set(state.stagedBotInfo, payload.id, payload.info);
  },
};

const actions = {
  fetchInfo({
    state, commit, dispatch, rootGetters,
  }, payload) {
    if (state.stagedBotInfo[payload.id] !== undefined) {
      return Promise.resolve();
    }
    return stagedBotBackend.fetchStagedBotInfo(payload.id).then((resp) => {
      commit('setInfo', { id: payload.id, info: resp.data });
    }).catch((e) => {
      if (rootGetters['templateStore/notificationsSorted'].map((item) => item.title)
        .includes('Failed to fetch Staged Bot Info')) {
        console.log(`Failed to fetch Staged Bot Info, id: ${payload.id}, error: ${e.message}`);
      } else {
        dispatch('templateStore/templateSendNotification', {
          title: 'Failed to fetch Staged Bot Info',
          text: `${e.message}, id: ${payload.id}`,
          variant: 'warning',
          toast: true,
        }, { root: true });
      }
    });
  },
  async fetchSetInfo(dummy, payload) {
    return stagedBotBackend.fetchVariantSetInfo(payload.id);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
