<template>
  <div>
    <div class="d-flex mb-2">
      <b-input
        ref="adaptationsetidinput"
        v-model="adaptationSetId"
        placeholder="Id of Variant/Staged Bot Set (from BotStudio)"
        title="Variant Set Id"
        type="text"
        :state="$v.adaptationSetId.$invalid ? false : null"
        required
      />
      <b-button
        :disabled="$v.adaptationSetId.$invalid"
        @click="useAdaptationSetId(adaptationSetId)"
      >
        Add
      </b-button>
      <b-button
        class="ml-2"
        @click="prune"
      >
        Prune
      </b-button>
      <b-button
        class="ml-2"
        @click="clear"
      >
        Clear
      </b-button>
    </div>
    <hr>
    <AdaptationRow
      v-for="row in value"
      :key="row.id"
      :value="row"
      @input="v => updateValue(row.id, v)"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { UUID_REGEX } from '@/js/utils';
import AdaptationRow from './AdaptationRow.vue';

export default {
  name: 'AdaptationSetup',
  components: { AdaptationRow },
  mixins: [validationMixin],
  props: {
    value: {
      type: Array,
      required: true,
    },
    extra: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      adaptationSetId: this.extra,
    };
  },
  methods: {
    ...mapActions('stagedbots', [
      'fetchSetInfo',
    ]),
    clear() {
      this.$emit('input', []);
    },
    prune() {
      // Kludge to vue store happy
      const emitted = [];
      for (let i = 0; i < this.value.length; i++) {
        if (this.value[i].count > 0) {
          emitted.push(this.value[i]);
        }
      }
      this.$emit('input', emitted);
    },
    async useAdaptationSetId(id) {
      try {
        const resp = await this.fetchSetInfo({ id });
        const stagedBotIds = resp.data.staged_bots.map((e) => e.id);
        for (const stagedBotId of stagedBotIds) {
          const exists = this.value.some((e) => e.id === stagedBotId);
          if (!exists) {
            this.$emit('newObject', { id: stagedBotId, count: 1 });
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    updateValue(id, value) {
      if (value === '') {
        return;
      }
      const original = this.value.find((x) => x.id === id);
      this.$emit('change', { ...original, count: parseInt(value, 10) });
    },
  },
  validations: {
    adaptationSetId: {
      required,
      isUUID(value) {
        if (!value) {
          return false;
        }
        return !!value.trim().match(UUID_REGEX);
      },
    },
  },
};
</script>

<style scoped>
</style>
