<template>
  <div
    v-if="!isLoaded"
    class="d-block text-center"
  >
    <b-spinner />
  </div>
  <div v-else>
    <h3>Incoming queues</h3>
    <b-card
      no-body
    >
      <p class="mb-0">
        Here you can specify how conversations are routed by defining
        different incoming queues and adding bot labels.
      </p>
    </b-card>
    <hr>
    <b-form-checkbox
      v-model="options.mustMatchLanguage"
      class="mb-2"
    >
      Conversations should only be routed to Bots with matching language.
    </b-form-checkbox>
    <hr>
    <div
      class="px-2 w-100"
    >
      <table-data
        borderless
        show-empty
        tbody-tr-class="bg-info text-white shadow-sm p-2 my-2 text-break"
        table-class="spaced-table"
        add-button-text="Configure Additional Queue"
        small
        :fields="fields"
        :items="Object.keys(queues)"
        @add="()=>$bvModal.show('new-queue')"
      >
        <template #empty>
          <div class="text-center">
            No known departments/queues. Problems with logging in?
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button
            v-b-tooltip.hover.noninteractive.viewport
            title="Delete"
            variant="danger"
            class="ml-1 action-button"
            size="sm"
            @click="deleteQueue(data.item)"
          >
            <font-awesome-icon icon="trash-alt" />
          </b-button>
        </template>
        <template #cell(queue)="data">
          {{ data.item }}
        </template>
        <template #cell(botLabels)="data">
          <b-row no-gutters>
            <b-col>
              <b-button
                v-for="(label, index) in queues[data.item].labels"
                :key="index"
                class="mr-2"
                variant="primary"
                pill
              >
                {{ label }}
                <font-awesome-icon
                  icon="times-circle"
                  size="lg"
                  @click.stop="removeLabel(data.item, label)"
                />
              </b-button>
            </b-col>
            <b-col cols="auto">
              <b-button
                variant="success"
                size="sm"
                :disabled="!knownLabels || knownLabels.length === 0"
                @click="addLabelsClicked(data.item)"
              >
                <font-awesome-icon icon="plus" />
              </b-button>
            </b-col>
          </b-row>
        </template>
      </table-data>
    </div>
    <hr>
    <b-button
      variant="primary"
      :disabled="!isDirty"
      class="mt-3 d-block px-5"
      @click="saveState"
    >
      Save
    </b-button>
    <div
      v-if="isDirty"
      class="unsaved-text"
    >
      *Unsaved changes
      <b-alert
        class="mt-2"
        variant="warning"
        show
      >
        If you have added or removed a queue you have to reset system connection before the bot
        will pick up conversations with the updated settings.
      </b-alert>
    </div>
    <b-modal
      id="new-queue"
      title="Configure Additional Queue"
      ok-title="Add"
      @ok="addQueue"
    >
      <queue-name-input v-model="newQueue" />
    </b-modal>
    <b-modal
      id="add-labels"
      title="Add Queue Labels"
      ok-title="Add"
      @hide="activeQueue=null"
    >
      <chip-list
        v-if="activeQueue"
        add-button-text="New Label"
        :completions="knownLabels"
        :value="queues[activeQueue].labels"
        @input="labels => updateLabels(labels)"
      />
    </b-modal>
  </div>
</template>
<script>
import { deepCopyJson, deepEqualsJson } from 'supwiz/util/data';
import { mapActions, mapGetters } from 'vuex';
import TableData from 'supwiz/components/TableData.vue';
import Vue from 'vue';
import ChipList from 'supwiz/components/ChipList.vue';
import QueueNameInput from '@/components/QueueNameInput.vue';

export default {
  name: 'IncomingQueues',
  components: {
    QueueNameInput,
    TableData,
    ChipList,
  },
  props: {
    isLoaded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      queues: {},
      options: {
        mustMatchLanguage: false,
      },
      fields: [
        { key: 'actions', label: '' },
        'queue',
        'botLabels',
      ],
      newQueue: '',
      activeQueue: null,
    };
  },
  computed: {
    ...mapGetters('chatsystem', [
      'getQueues',
      'getDefaultErrorHandlers',
      'getMustMatchLanguage',
    ]),
    ...mapGetters('bots', ['knownLabels']),
    isOptionsDirty() {
      return this.options.mustMatchLanguage !== this.getMustMatchLanguage;
    },
    isQueuesDirty() {
      return !deepEqualsJson(this.queues, this.getQueues);
    },
    isDirty() {
      return this.isOptionsDirty || this.isQueuesDirty;
    },
    hasData() {
      return this.queues && Object.values(this.queues).length;
    },
  },
  watch: {
    isDirty(newVal) {
      this.$emit('unsavedChanges', newVal);
    },
    getQueues(newValue) {
      if (deepEqualsJson(newValue, this.queues)) {
        return;
      }
      if (this.isLoaded) {
        this.$bvToast.toast('Queues have been changed by someone else; '
            + 'you may experience edit conflicts and loss of data', {
          title: 'Queues routing changed',
          autoHideDelay: 1000,
          variant: 'warning',
        });
      }
      Vue.set(this, 'queues', deepCopyJson(newValue));
    },
    getMustMatchLanguage(newValue) {
      if (newValue === this.options.mustMatchLanguage) {
        return;
      }
      if (this.isLoaded) {
        this.$bvToast.toast('Options have been changed by someone else; '
            + 'you may experience edit conflicts and loss of data', {
          title: 'Option changed',
          autoHideDelay: 1000,
          variant: 'warning',
        });
      }
      this.$set(this.options, 'mustMatchLanguage', !!newValue);
    },
  },
  beforeDestroy() {
    this.$emit('unsavedChanges', false);
  },
  mounted() {
    Vue.set(this, 'queues', deepCopyJson(this.getQueues));
    this.$set(this.options, 'mustMatchLanguage', this.getMustMatchLanguage);
  },
  methods: {
    ...mapActions('chatsystem', [
      'sendRoutingConfig',
    ]),
    addQueue() {
      Vue.set(this.queues, this.newQueue, {
        labels: [],
        errorHandlers: [],
      });
    },
    deleteQueue(name) {
      Vue.delete(this.queues, name);
    },
    addLabelsClicked(value) {
      this.activeQueue = value;
      this.$bvModal.show('add-labels');
    },
    updateLabels(labels) {
      this.queues[this.activeQueue].labels = labels;
    },
    removeLabel(item, label) {
      const index = this.queues[item].labels.indexOf(label);
      this.queues[item].labels.splice(index, 1);
    },
    saveState() {
      this.$emit('saveClicked', null);
      this.sendRoutingConfig({
        queues: this.queues,
        defaultErrorHandlers: this.getDefaultErrorHandlers,
        options: this.options,
      });
    },

  },
};
</script>
<style scoped>
::v-deep .spaced-table {
  font-size: 0.9375rem;
  border-collapse: separate;
  border-spacing: 0 0.5rem;
}
</style>
