var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-alert',{staticClass:"m-0 py-2 text-center deployment-alert r-0",class:_vm.botAdminEnvironment!=='production'?'mt-1':'',attrs:{"variant":"secondary","fade":"","show":_vm.showDeploymentAlert && _vm.loggedIn}},[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',[_vm._v(" Deployment: "+_vm._s(_vm.botAdminName)+" ")]),_c('font-awesome-icon',{staticClass:"pr-3 pt-1 cursor-pointer",staticStyle:{"float":"right"},attrs:{"icon":"times"},on:{"click":function($event){_vm.showDeploymentAlert=false}}})],1)],1),_c('app-template',_vm._b({attrs:{"notifications-center":""},scopedSlots:_vm._u([{key:"before-nav",fn:function(){return [(_vm.hasDataOrigin && !_vm.sidebarCompact)?_c('b-badge',{staticClass:"mx-3 font-weight-normal",attrs:{"variant":_vm.dataOriginVariant}},[_vm._v(" DATA ORIGIN: "+_vm._s(_vm.chatsystemsState[0].status.settings.data_origin)+" ")]):_vm._e(),(!_vm.showDeploymentAlert && !_vm.sidebarCompact)?_c('b-badge',{staticClass:"mx-3 mt-2 font-weight-normal"},[_vm._v(" Deployment: "+_vm._s(_vm.botAdminName)+" ")]):_vm._e()]},proxy:true},{key:"page-content",fn:function(){return [_c('transition',{attrs:{"mode":"out-in","name":"fade"}},[_c('router-view')],1)]},proxy:true}])},'app-template',{
      pages: _vm.pages,
      showSidebar: _vm.showSidebar,
      userDetails: _vm.userDetails,
      toolDetails: {
        name: 'BotAdmin',
        logo: require('./assets/supwiz-logo-flat.svg'),
        env:_vm.botAdminEnvironment
      },
      loggedIn: _vm.loggedIn,
    },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }