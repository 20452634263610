<template>
  <b-card
    class="r-75 my-2"
  >
    <b-card-title>
      <deployment-status default-title="Deploy Bot" />
    </b-card-title>
    <b-row>
      <b-col class="pr-0">
        <b-form-input
          ref="stagedbotinput"
          v-model="botToStage"
          placeholder="Id of staged bot version (from BotStudio)"
          type="text"
          required
        />
      </b-col>
      <b-col
        cols="auto"
      >
        <b-button
          v-b-tooltip.hover
          title="Paste Staging Id"
          @click="pasteStagingId"
        >
          <font-awesome-icon
            icon="paste"
            size="lg"
          />
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-button
          variant="primary"
          :disabled="$v.botToStage.$invalid"
          @click="deployBot"
        >
          Deploy Bot
        </b-button>
      </b-col>
      <b-col>
        <b-button
          v-b-modal.adaptation-modal
          class="float-right"
          variant="primary"
          :disabled="($v.botToStage.$invalid && !adaptationsList)"
        >
          Deploy Variants
        </b-button>
      </b-col>
    </b-row>

    <b-modal
      id="adaptation-modal"
      title="Variants Settings"
      size="lg"
      @ok="deployAdaptation"
      @show="copyAdaptations"
    >
      <AdaptationSetup
        :value="adaptationsList"
        :extra="botToStage"
        @input="v=>adaptationsList=v"
        @newObject="v=>adaptationsList.push(v)"
        @change="changeAdaptationsList"
      />
      <template #modal-footer="{ cancel, ok }">
        <b-button
          variant="secondary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="warning"
          @click="deployAdaptation()"
        >
          Apply
        </b-button>
        <b-button
          variant="success"
          @click="ok()"
        >
          OK
        </b-button>
      </template>
      <hr>
      <b-row align-v="center">
        <b-col cols="3">
          <b-form-select
            v-model="fillFreeWithTmp"
            :options="[
              {value: 'Default', text: 'Default'},
              {value: 'Unchanged', text: 'Unchanged'},
              {value: 'Clear', text: 'Clear'},
              {value: 'None', text: 'None', disabled: true},
            ]"
          />
        </b-col>
        How should unassigned Workers be deployed
      </b-row>
      <b-row align-v="center">
        <b-col cols="3">
          <b-form-input
            v-model.number="maxWorkersUpdatingTmp"
            type="number"
            min="0"
          />
        </b-col>
        Max concurrent updating workers (0 is unlimited)
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { UUID_REGEX } from '@/js/utils';
import AdaptationSetup from '@/components/AdaptationSetup.vue';
import DeploymentStatus from '@/components/DeploymentStatus.vue';

export default {
  name: 'AdminDeployBot',
  components: { AdaptationSetup, DeploymentStatus },
  mixins: [validationMixin],
  data() {
    return {
      botToStage: '',
      adaptationsList: [],
      maxWorkersUpdatingTmp: 0,
      fillFreeWithTmp: 'Unchanged',
    };
  },
  computed: {
    ...mapGetters('bots', [
      'adaptations',
      'maxWorkersUpdating',
      'fillFreeWith',
    ]),
    hasAdaptations() {
      return this.adaptationsList && this.adaptationsList.length > 0;
    },
  },
  methods: {
    ...mapActions('bots', {
      doDeployBot: 'deployBot',
    }),
    async deployBot(event) {
      event.preventDefault(); // Don't refresh page
      if (this.botToStage) {
        this.botToStage = this.botToStage.trim();
        await this.doDeployBot({
          defaultStagedBotId: this.botToStage,
          adaptations: {},
          fillFreeWith: 'Default',
        });
      }
    },
    async deployAdaptation() {
      const adaptations = {};
      for (const { id, count } of this.adaptationsList) {
        adaptations[id] = count;
      }
      this.doDeployBot({
        adaptations,
        maxWorkersUpdating: this.maxWorkersUpdatingTmp,
        fillFreeWith: this.fillFreeWithTmp,
      });
    },
    changeAdaptationsList(value) {
      const index = this.adaptationsList.findIndex((x) => x.id === value.id);
      this.$set(this.adaptationsList, index, value);
    },
    copyAdaptations() {
      if (this.adaptations) {
        this.adaptationsList = Object.entries(this.adaptations).map((e) => ({
          id: e[0],
          count: e[1],
        }));
        this.maxWorkersUpdatingTmp = this.maxWorkersUpdating;
        this.fillFreeWithTmp = this.fillFreeWith;
      }
    },
    async pasteStagingId() {
      const clipboard = navigator.clipboard;
      if (clipboard) {
        if (clipboard.readText) {
          // awaited, as browser may ask user permission
          this.botToStage = await clipboard.readText();
        } else {
          this.$bvToast.toast('Clipboard Read Permission Denied', {
            title: 'Clipboard',
            autoHideDelay: 1000,
            variant: 'danger',
          });
        }
      }
    },
  },
  validations: {
    botToStage: {
      required,
      isUUID(value) {
        if (!value) {
          return false;
        }
        return !!value.trim().match(UUID_REGEX);
      },
    },
  },
};
</script>
