<template>
  <b-card
    class="r-75 my-2"
    title="Worker status"
    body-class="p-3"
  >
    <b-table
      ref="workertable"
      :select-mode="selectMode"
      :items="botsCurrentState"
      :fields="workerFields"
      :busy="waitForWorkerInfo"
      selectable
      borderless
      hover
      @row-selected="updateSelectedWorkers"
    >
      <template #table-busy>
        <b-alert
          show
          variant="warning"
        >
          No Workers running
        </b-alert>
      </template>
      <template #cell(Bot)="item">
        <b-row
          v-if="item.value"
          no-gutters
          align-v="center"
        >
          <staged-bot-id
            :id="item.value"
          />
          <b-button
            v-b-tooltip.hover
            class="ml-1"
            title="Copy ID to clipboard"
            size="sm"
            @click="copyToClipboard(item.value)"
          >
            <font-awesome-icon icon="copy" />
          </b-button>
        </b-row>
      </template>
      <template
        #cell(Actions)="row"
      >
        <b-btn-group>
          <b-button
            v-b-tooltip.hover
            size="sm"
            title="Worker information"
            @click="getWorkerStatus(row)"
          >
            <font-awesome-icon icon="info" />
          </b-button>
          <b-button
            v-b-tooltip.hover
            title="Clear staged bot ID"
            size="sm"
            @click="doResetWorkerBotId(row)"
          >
            <font-awesome-icon icon="stop-circle" />
          </b-button>
          <b-button
            v-b-tooltip.hover
            size="sm"
            title="Kill worker"
            @click.exact="doKillWorkerPod(row)"
            @click.shift.exact="doResetWorker(row)"
            @click.ctrl.exact="doOOMKillWorker(row)"
          >
            <font-awesome-icon icon="skull-crossbones" />
          </b-button>
        </b-btn-group>
      </template>
    </b-table>
    <b-row>
      <b-col
        cols="auto"
        class="ml-auto"
      >
        <!-- This is the Bootstrap-Vue documented way of putting a hover on a disabled element-
        sorry, but it could not be done more elegantly.  -->
        <div
          v-if="botsCurrentState.length > 0"
        >
          <span
            id="inactivate-bots"
            class="d-inline-block"
          >
            <b-button
              class="mr-2"
              :disabled="selectedWorkers.length === 0"
              variant="primary"
              @click="makeSelectedBotsInactive"
            >
              Make worker(s) inactive
            </b-button>
          </span>

          <b-tooltip
            v-if="selectedWorkers.length === 0"
            target="inactivate-bots"
            triggers="hover"
          >
            Before inactivating, click a row to select a worker.
          </b-tooltip>

          <span
            id="activate-bots"
            class="d-inline-block"
          >
            <b-button
              v-if="botsCurrentState.length > 0"
              :disabled="selectedWorkers.length === 0"
              variant="primary"
              @click="makeSelectedBotsActive"
            >
              Make worker(s) active
            </b-button>
          </span>
          <b-tooltip
            v-if="selectedWorkers.length === 0"
            target="activate-bots"
            triggers="hover"
          >
            Before activating, click a row to select a worker.
          </b-tooltip>
        </div>

        <div
          v-if="botsCurrentState.length > 0"
        />
      </b-col>
    </b-row>

    <b-modal
      id="worker-status-modal"
      ok-only
      size="lg"
      :title="workerStateId"
    >
      <pre>{{ prettyWorkerStateText }}</pre>
    </b-modal>
  </b-card>
</template>

<script>
import copy from 'clipboard-copy';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import StagedBotId from '@/components/StagedBotId.vue';

export default {
  name: 'AdminWorkerStatus',
  components: { StagedBotId },
  data() {
    return {
      selectMode: 'multi',
      selectedWorkers: [],
    };
  },
  computed: {
    ...mapGetters('bots', [
      'workerStateId',
      'workerStateText',
      'botsCurrentState',
      'botsCurrentStateTimestamp',
    ]),
    workerFields() {
      if (this.botsCurrentState.length === 0) {
        return [];
      }
      const k = Object.keys(this.botsCurrentState[0]);
      k.push('Actions');

      return k;
    },
    prettyWorkerStateText() {
      return JSON.stringify(this.workerStateText, null, 2);
    },
    waitForWorkerInfo() {
      return this.botsCurrentStateTimestamp != null && this.botsCurrentState.length === 0;
    },
  },
  methods: {
    ...mapMutations('bots', [
      'updateWorkerStateId',
    ]),
    ...mapActions('bots', [
      'deactivateBots',
      'activateBots',
      'requestWorkerState',
      'killWorkerPod',
      'resetWorker',
      'oomKillWorker',
      'resetWorkerBotId',
    ]),
    updateSelectedWorkers(selectedWorkers) {
      this.selectedWorkers = [];
      selectedWorkers.forEach((element) => {
        this.selectedWorkers.push(element['Worker pod']);
      });
    },
    makeSelectedBotsInactive() {
      this.deactivateBots(this.selectedWorkers);
    },
    makeSelectedBotsActive() {
      this.activateBots(this.selectedWorkers);
    },
    getWorkerStatus(row) {
      const workerId = row.item['Worker pod'];
      this.updateWorkerStateId({ workerId });
      this.requestWorkerState(workerId);
      this.$bvModal.show('worker-status-modal');
    },
    doKillWorkerPod(row) {
      const workerId = row.item['Worker pod'];
      this.killWorkerPod({ workerIds: [workerId] });
    },
    doResetWorker(row) {
      const workerId = row.item['Worker pod'];
      this.resetWorker({ workerIds: [workerId] });
    },
    doOOMKillWorker(row) {
      const workerId = row.item['Worker pod'];
      this.oomKillWorker({ workerIds: [workerId] });
    },
    doResetWorkerBotId(row) {
      const workerId = row.item['Worker pod'];
      this.resetWorkerBotId({ workerIds: [workerId] });
    },
    copyToClipboard(value) {
      copy(value);
    },
  },
};
</script>
