<template>
  <div>
    <b-card
      class="r-75 my-2"
      title="Admin Commands"
      body-class="p-3"
    >
      <b-button
        v-b-tooltip.hover.noninteractive.viewport
        :v-if="isSuperuser"
        class="mr-2"
        variant="warning"
        title="Restarting BotServer will stop all conversations"
        @click="restartControl"
      >
        <font-awesome-icon
          icon="exclamation-circle"
        />
        Restart BotServe
      </b-button>
      <b-button
        v-b-tooltip.hover.noninteractive.viewport
        class="mr-2"
        title="Dump Tasks For Debugging"
        @click="dumpTasks"
      >
        Dump Tasks
      </b-button>
      <b-button
        @click="showRawState"
      >
        Show Raw State
      </b-button>
    </b-card>

    <b-modal
      id="raw-state"
      ok-only
      size="lg"
      title="Raw state"
    >
      <vue-json-pretty
        :data="JSON.parse(prettyBotserveStateText)"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VueJsonPretty from 'vue-json-pretty';

export default {
  name: 'AdminCommands',
  components: {
    VueJsonPretty,
  },
  computed: {
    ...mapGetters('auth', ['isSuperuser']),
    ...mapGetters('control', ['controlState']),
    prettyBotserveStateText() {
      return JSON.stringify(this.controlState, null, 2);
    },
  },
  methods: {
    ...mapActions('control', ['restartControl', 'dumpTasks']),
    showRawState() {
      this.$bvModal.show('raw-state');
    },
  },
};
</script>

<style scoped>
</style>
