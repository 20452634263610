<template>
  <b-row
    align-v="center"
    class="mb-2"
  >
    <b-col cols="10">
      <staged-bot-id
        :id="value.id"
        :disabled="value.count <= '0'"
      />
      <b-badge
        v-if="value.state && value.state !== 'exists'"
        :variant="value.state === 'new' ? 'success' : 'danger'"
        :disabled="value.count === 0"
      >
        {{ value.state }}
      </b-badge>
    </b-col>
    <b-col>
      <b-input
        :value="value.count"
        type="number"
        min="0"
        size="sm"
        @input="v=>$emit('input', v)"
      />
    </b-col>
  </b-row>
</template>

<script>
import StagedBotId from '@/components/StagedBotId.vue';

export default {
  name: 'AdaptationRow',
  components: { StagedBotId },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped>
</style>
