<template>
  <b-container>
    <b-row class="justify-content-md-center pt-5">
      <b-col
        cols="12"
        lg="8"
      >
        <b-card
          align="center"
          title="Sign in to BotAdmin"
          :header-bg-variant="isNonProductionEnvironment ? 'warning' : 'default'"
        >
          <b-row>
            <b-col>
              <b-alert
                v-if="!loginInProgress && (errorCode || aadError)"
                show
                fade
                variant="danger"
              >
                <template v-if="errorCode === 401">
                  Unauthorized: Username / password combination wrong.
                </template>
                <template v-if="errorCode === 500">
                  Server error!
                </template>
                <div v-if="aadError === 'unexpected_error' || aadError === 'wrong_state'">
                  An unexpected error occurred while trying to sign in.
                </div>
                <div v-if="aadError === 'not_allowed'">
                  The user you signed in with is not authorized for the BotAdmin dashboard.
                </div>
              </b-alert>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form @submit.prevent="doLogin">
                <b-form-group
                  id="username-group"
                  class="text-left"
                  label="Username"
                  label-for="username"
                  label-size="sm"
                >
                  <b-form-input
                    id="username"
                    v-model="input.username"
                    type="text"
                    required
                    placeholder="Username"
                    autocomplete="username"
                    autofocus
                  />
                </b-form-group>

                <b-form-group
                  id="password-group"
                  class="text-left"
                  label="Password"
                  label-for="password"
                  label-size="sm"
                >
                  <b-form-input
                    id="password"
                    v-model="input.password"
                    type="password"
                    required
                    autocomplete="current-password"
                    placeholder="Password"
                  />
                </b-form-group>

                <b-button
                  type="submit"
                  variant="primary"
                  style="width:100%"
                  class="mb-2"
                  :disabled="loginInProgress"
                >
                  <b-spinner
                    v-if="loginInProgress"
                    small
                  />
                  Sign in
                </b-button>
              </b-form>
            </b-col>
          </b-row>

          <div v-if="ssoEnabled">
            <b-link :href="ssoPath">
              Sign in with your organization account
            </b-link>
          </div>
          <div>
            <b-link href="accounts/password_reset">
              Reset your BotAdmin password
            </b-link>
          </div>
          <div slot="footer">
            <img
              style="max-width: 25%"
              src="@/assets/supwiz_dark_text.svg"
              alt="SupWiz Logo"
            >
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import { getBotAdminEnvironment } from '@/js/utils';
import { endpoints } from '@/backend/endpoints';

export default {
  name: 'Login',
  data() {
    return {
      input: {
        username: '',
        password: '',
      },
      loginInProgress: false,
      ssoEnabledResponse: false,
    };
  },
  computed: {
    ...mapGetters({
      errorCode: 'auth/errorCode',
    }),
    ssoEnabled() {
      return this.ssoEnabledResponse;
    },
    ssoPath() {
      return endpoints.ssoLogin;
    },
    aadError() {
      return this.$route.query.aad_error;
    },
    isNonProductionEnvironment() {
      // This really is not a computed property; it will never realistically change during runtime.
      return getBotAdminEnvironment().toLowerCase() !== 'production';
    },
    botadminEnvironment() {
      return getBotAdminEnvironment();
    },
  },
  async created() {
    const response = await axios.get(endpoints.ssoEnabled);
    this.ssoEnabledResponse = response.data.toLowerCase() === 'true';
  },
  methods: {
    ...mapActions('auth', [
      'login',
    ]),
    async doLogin() {
      const username = this.input.username;
      const password = this.input.password;
      this.loginInProgress = true;
      try {
        const { success } = await this.login({ username, password });
        if (success) {
          this.$router.push({ name: 'manage' });
        }
      } finally {
        this.loginInProgress = false;
      }
    },
  },
};
</script>
