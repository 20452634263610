<template>
  <b-card
    title="Audit log"
    class="r-75"
    body-class="p-3"
  >
    <b-row v-if="pagination.totalRows > pagination.itemsPerPage">
      <b-col
        cols="3"
      >
        <b-pagination
          v-model="pagination.currentPage"
          :total-rows="pagination.totalRows"
          :per-page="pagination.itemsPerPage"
          size="sm"
        />
      </b-col>
    </b-row>
    <b-table
      ref="auditLogTable"
      :items="tableProvider"
      :fields="assignedLabelsTableFields"
      :per-page="pagination.itemsPerPage"
      :current-page="pagination.currentPage"
      show-empty
      striped
    >
      <template #empty>
        No Audit logs
      </template>
      <template #cell(audit_type)="data">
        <template
          v-if="data.item.audit_type === 'deploy_bot'"
        >
          <template
            v-if="data.item.audit_description.workers &&
              data.item.audit_description.workers.length !== 0"
          >
            Deploy Selected
          </template>
          <template
            v-else-if="data.item.audit_description.adaptations"
          >
            Deploy Variants
          </template>
          <template
            v-else
          >
            Deploy Bot
          </template>
        </template>

        <template
          v-else-if="data.item.audit_type === 'alter_bot'"
        >
          Manipulate workers
        </template>

        <template
          v-else-if="data.item.audit_type === 'manipulate_chatsystem'"
        >
          Manipulate support system
        </template>

        <template
          v-else-if="data.item.audit_type === 'manipulate_settings'"
        >
          Changed Settings
        </template>

        <template
          v-else-if="data.item.audit_type === 'manipulate_routing'"
        >
          Manipulate routing
        </template>

        <template
          v-else-if="data.item.audit_type === 'worker-command'"
        >
          Worker Command
        </template>

        <template
          v-else-if="data.item.audit_type === 'update_deployment'"
        >
          Deployment Update
        </template>

        <!-- Include an else, so that we're more likely to discover any unhandled audit_types.
        Also, some information (although slightly technical) is likely to still be more valuable to
        endusers than none information -->
        <template
          v-else
        >
          {{ data.item.audit_type }}
        </template>
      </template>

      <template #cell(audit_description)="data">
        <template
          v-if="data.item.audit_type === 'deploy_bot'"
        >
          New bot {{ data.item.audit_description.new_bot_id }}
        </template>

        <template
          v-else-if="data.item.audit_type === 'alter_bot'"
        >
          Action {{ data.item.audit_description.action }} <br>
          Workers: {{ data.item.audit_description.workers.join() }}
        </template>

        <template
          v-else-if="data.item.audit_type === 'manipulate_settings'"
        >
          {{ data.item.audit_description }}  <br>Actual values are removed
        </template>

        <template
          v-else-if="data.item.audit_type === 'manipulate_chatsystem'"
        >
          <template
            v-if="data.item.audit_description.action === 'OFFLINE'"
          >
            Reset support system connection
          </template>

          <template
            v-if="data.item.audit_description.action === 'ONLINE'"
          >
            Activate support system status
          </template>

          <template
            v-if="data.item.audit_description.action === 'INVISIBLE'"
          >
            Deactivate support system status
          </template>
        </template>

        <template
          v-else-if="data.item.audit_type === 'worker-command'"
        >
          Command {{ data.item.audit_description.command }}
          ({{ data.item.audit_description.commandArg }}) <br>
          Workers: {{ data.item.audit_description.workerIds.join() }}
        </template>

        <template
          v-else-if="data.item.audit_type === 'update_deployment'"
        >
          <div v-if="data.item.audit_description.default_staged_bot_id">
            default: {{ data.item.audit_description.default_staged_bot_id }}
          </div>
          <div v-if="data.item.audit_description.workers">
            workers: {{ data.item.audit_description.workers }}
          </div>
          <div v-if="data.item.audit_description.adaptations">
            adaptations: {{ data.item.audit_description.adaptations }}
          </div>
        </template>

        <!-- Include an else, so that we're more likely to discover any unhandled audit_types.
        Also, some information (although slightly technical) is likely to still be more valuable to
        endusers than none information, for instance: although technical, it still vitnesses that
        the system did record that the user did something -->
        <template
          v-else
        >
          {{ data.item.audit_description }}
        </template>
      </template>

      <template #cell(created)="data">
        {{ dateAndTimeFromTimestamp(data.item.created) }}
      </template>
    </b-table>
  </b-card>
</template>

<script>
import axios from 'axios';
import { endpoints } from '@/backend/endpoints';
import { authHeader } from '@/helpers/authHeader';
import dateAndTimeFromTimestamp from '@/js/filters';
import eventBus from '@/eventBus';
import { UUID_REGEX } from '@/js/utils';

export default {
  name: 'AuditLogTable',
  data() {
    return {
      pagination: {
        totalRows: 0,
        itemsPerPage: 8,
        currentPage: 1,
      },
      assignedLabelsTableFields: [
        {
          key: 'created',
          label: 'Timestamp',
        },
        {
          key: 'audit_type',
          label: 'Audit type',
        },
        {
          key: 'audit_description',
          label: 'Details',
        },
        {
          key: 'user',
          label: 'User',
        },
      ],
    };
  },
  mounted() {
    eventBus.$on('auditLogUpdated', this.refreshAuditLogTable);
  },
  beforeDestroy() {
    eventBus.$off('auditLogUpdated', this.refreshAuditLogTable);
  },
  methods: {
    dateAndTimeFromTimestamp,
    refreshAuditLogTable() {
      this.$refs.auditLogTable.refresh();
    },
    async tableProvider(context) {
      const desiredPage = context.currentPage;
      const itemsPerPage = context.perPage;
      const params = {
        requested_page: desiredPage,
        per_page: itemsPerPage,
      };
      const config = {
        headers: authHeader(),
        params,
      };
      const response = await axios.get(endpoints.logs, config);
      this.pagination.totalRows = response.data.total_entries;

      // transformEntry is needed to handle AD-logged in users, so that a human-recognizable'
      // display-name is shown for AD-logged in users (rather than showing a UUID )
      function transformEntry(entry) {
        const copy = { ...entry };
        const usernameIsUUID = entry.user.username.match(UUID_REGEX);
        let user = '';
        if (usernameIsUUID) {
          user = entry.user.email;
        } else {
          user = entry.user.username;
        }
        copy.user = user;
        return copy;
      }

      return response.data.entries_in_page.map(transformEntry);
    },
  },
};
</script>
