import Vue from 'vue';
import Vuex from 'vuex';
import templateStore from 'supwiz/components/template/templateStore';
import auth from '@/store/auth';
import bots from '@/store/bots';
import meta from '@/store/meta';
import chatsystem from '@/store/chatsystem';
import speech from '@/store/speech';
import email from '@/store/email';
import aiEngine from '@/store/aiEngine';
import control from '@/store/control';
import sidebar from '@/store/sidebar';
import stagedbots from '@/store/stagedbots';
import supSearch from '@/store/supSearch';
import sms from '@/store/sms';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    bots,
    stagedbots,
    meta,
    chatsystem,
    speech,
    sms,
    email,
    aiEngine,
    sidebar,
    supSearch,
    control,
    templateStore,
  },
});
