import '@/public-path';
import VueLogger from 'vuejs-logger';
import BootstrapVue from 'bootstrap-vue';
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import router from '@/router';
import store from '@/store/index';
import App from '@/App.vue';

import '@/js/icons';

import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'supwiz/styling/supwiz_bootstrap.scss';

const isProduction = process.env.NODE_ENV === 'production';

Vue.config.productionTip = false;

const loggerOptions = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
};

Vue.use(VueLogger, loggerOptions);
Vue.use(BootstrapVue);
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

const vue = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

if (!isProduction) {
  const consoleErrorOrg = console.error;
  // eslint-disable-next-line no-inner-declarations
  function consoleErrorMock() {
    // eslint-disable-next-line prefer-rest-params
    consoleErrorOrg(...arguments);
    if (vue) {
      vue.$bvToast.toast('Console has errors', {
        title: '[SupwizDEV]: An error occurred in console',
        appendToast: true,
        variant: 'danger',
      });
    }
  }
  console.error = consoleErrorMock;
}
